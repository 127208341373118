import { gql } from '@apollo/client'
import PRODUCT_FRAGMENTS from 'containers/gqls/products/product/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const PRODUCT_MUTATION_GQLS = {
  CREATE_PRODUCT_BY_FITPET_ADMIN: gql`
    mutation createProductByFitpetAdmin($input: ProductInput!) {
      createProductByFitpetAdmin(input: $input) {
        product {
          ...productDetail
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productDetail}
  `,
  CREATE_PRODUCT_BY_SELLER: gql`
    mutation createProductBySeller($input: ProductInput!) {
      createProductBySeller(input: $input) {
        product {
          ...productDetail
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productDetail}
  `,
  UPDATE_PRODUCT_BY_FITPET_ADMIN: gql`
    mutation updateProductByFitpetAdmin($id: ID!, $input: ProductInput!) {
      updateProductByFitpetAdmin(id: $id, input: $input) {
        product {
          ...productDetail
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productDetail}
  `,
  UPDATE_PRODUCT_BY_SELLER: gql`
    mutation updateProductBySeller($id: ID!, $input: ProductInput!) {
      updateProductBySeller(id: $id, input: $input) {
        product {
          ...productDetail
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productDetail}
  `,
  DELETE_INCOMPLETE_PRODUCTS: gql`
    mutation deleteIncompleteProducts($input: ModelDeletesInput!) {
      deleteIncompleteProducts(input: $input) {
        count
      }
    }
  `,
  COPY_PRODUCT: gql`
    mutation copyProduct($id: ID!) {
      copyProduct(id: $id) {
        product {
          id
          name
          productProductImages {
            edges {
              node {
                id
                image
                thumbnail
              }
            }
          }
        }
      }
    }
  `,
  UPDATE_PRODUCTS_SOLD_OUT: gql`
    mutation updateProductsSoldOutDefault($input: ProductsSoldOutInput!) {
      updateProductsSoldOut(input: $input) {
        products {
          id
        }
      }
    }
  `,
  UPDATE_PRODUCTS_SELLING: gql`
    mutation updateProductsSellingDefault($input: ProductsSellingInput!) {
      updateProductsSelling(input: $input) {
        products {
          id
        }
      }
    }
  `,
  APPROVE_PRODUCTS_SELLING: gql`
    mutation approveProductsSellingDefault($input: ApproveProductsSellingInput!) {
      approveProductsSelling(input: $input) {
        products {
          id
        }
      }
    }
  `,
  UPDATE_PRODUCT_MILEAGES_BY_EXCEL: gql`
    mutation updateProductMileagesByExcel($input: BaseImportByExcelInput!) {
      updateProductMileagesByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  UPDATE_BULK_PRODUCTS_BY_EXCEL: gql`
    mutation updateBulkProductsByExcel($input: BaseImportByExcelInput!) {
      updateBulkProductsByExcelMutation(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
}

export default PRODUCT_MUTATION_GQLS
