import { gql } from '@apollo/client'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'
import SHIPPING_FRAGMENTS from 'containers/gqls/orders/shipping/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const SELLER_FRAGMENTS: { [index: string]: any } = {
  get sellerMinimum() {
    return gql`
      fragment sellerMinimum on SellerType {
        id
        shopName
        companyName
        approvedAt
        fitpetFeeRate
      }
    `
  },
  get seller() {
    return gql`
      fragment seller on SellerType {
        ...sellerOnly
        isFollowed
        users {
          edges {
            node {
              ...userOnly
            }
          }
        }
        status {
          ...status
        }
        accountHolderName
        bankName
        bankCode
        accountNumber
        userShippingPresets {
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              ...shippingPreset
            }
          }
          totalCount
        }
        sellerAddresses {
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              ...sellerTemplateAddress
            }
          }
          totalCount
        }
        chiefManager {
          ...userOnly
        }
      }
      ${this.sellerOnly}
      ${this.sellerTemplateAddress}
      ${DEFAULT_FRAGMENTS.pageInfo}
      ${USER_FRAGMENTS.userOnly}
      ${DEFAULT_FRAGMENTS.status}
      ${SHIPPING_FRAGMENTS.shippingPreset}
    `
  },
  get sellerOnly() {
    return gql`
      fragment sellerOnly on SellerType {
        createdAt
        updatedAt
        accountNumber
        accountHolderName
        bankCode
        id
        companyName
        shopName
        companyOwnerName
        businessRegistrationNumber
        postNumber
        basicAddress
        detailedAddress
        homepage
        businessCondition
        businessItem
        businessRegistration
        mailOrderReportNumber
        mailOrderReportRegistration
        bankBookImage
        telephoneNumber
        fitpetFeeRate
        accountManagerName
        accountManagerTelephoneNumber
        taxEmail
        notificationManagerName
        notificationEmail
        notificationMobileNumber
        returnExchangeGuide
        requestedAt
        approvedAt
        updateApprovedAt
        isFollowed
        chiefManager {
          id
          email
          name
          mobileNumber
        }
        bankName
      }
    `
  },
}

export default SELLER_FRAGMENTS
