/* eslint-disable @typescript-eslint/no-use-before-define */

import { TablePaginationConfig } from 'antd/lib/table'

import { themes } from '@constants/themes'

export const POLICY_DATA = {
  ORDER_AUTO_CANCEL_DAY_WHEN_UNPAID: 3,
  IMAGE_FILE_SIZE_BYTES: 10 * 1024 * 1024,
  DEFAULT_EXCEL_FILE_MAX_ROW_COUNT: 100000,
  DEFAULT_EXCEL_FILE_ROW_COUNT: 10000,
  USER_EXCEL_FILE_MAX_ROW_COUNT: 500000,
  REVIEW_WEIGHT_MAX_COUNT: 100,
}
export const S3_HOST_URL = 'https://fitpet-mall-v3.s3.ap-northeast-2.amazonaws.com'

const getS3Prefix = () => {
  if (process.env.FITPETMALL_ENV === 'production') {
    return 'prod'
  }
  if (process.env.FITPETMALL_ENV === 'rc') {
    return 'staging'
  }
  if (process.env.FITPETMALL_ENV === 'staging') {
    return 'staging'
  }
  return 'alpha'
}

export const S3_PREFIX = getS3Prefix()

export const FITPET_CS_TEL = '070-8874-1182'
export const FITPET_CS_EMAIL = 'info@fitpet.kr'
export const FITPET_TAX_EMAIL = 'ninitax@fitpet.co.kr'
export const REGISTER_BRAND_URL = 'https://docs.google.com/forms/d/1Mpne_LJs3PWuxWLiEmui_nWJef0T_c4gfIjOE6HJIj4/edit'
export const SELLER_GUIDE_DOWNLOAD_URL = `${S3_HOST_URL}/assets/files/user_guide/%5BFITPET%5DSELLER+manual_ALL.zip`
export const SELLER_GUIDE_DOWNLOAD_FILENAME = '%5BFITPET%5DSELLER_manual_ALL.pdf'
export const SELLER_SUPPORT_CENTER_URL = 'https://pf.kakao.com/_xhkrLb'

// eslint-disable-next-line max-len
export const EXCEL_IMPORT_TEMPLATE_URLS = {
  PRODUCT_UPLOAD_SAMPLE_BY_ADMIN: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_upload_sample_admin.xlsx`,
  PRODUCT_UPLOAD_SAMPLE_BY_SELLER: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_upload_sample_seller.xlsx`,
  PRODUCT_SEARCH_KEYWORD_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_search_keyword_upload_sample.xlsx`,
  PRODUCT_MILEAGE_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_mileage_upload_sample.xlsx`,
  MILEAGE_HISTORY_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/mileage_history_upload_sample.xlsx`,
  REMOTE_AREA_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/remote_area_upload_sample.xlsx`,
  UPDATE_PREFIX_SPECIAL_DISCOUNT_PRICE_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_name_prefix_price_sample.xlsx`,
  PRODUCT_PROMOTION_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_promotion_upload_sample.xlsx`,
  PRODUCT_REVIEW_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/product_review_upload_sample.xlsx`,
  SHIPMENT_WITH_SHIPPING_COMPANY_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/shipment_upload_sample_with_shipping_company.xlsx`,
  TODAY_SPECIAL_PRICE_UPLOAD_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/today_special_product_promotion_upload_sample.xlsx`,
  ISSUE_MANUAL_COUPON_SAMPLE: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/issue_manual_coupon_sample.xlsx`,
  SKU_STOCK_SAMPLE_ADMIN: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/sku_stock_sample_admin.xlsx`,
  SKU_STOCK_SAMPLE_SELLER: `${S3_HOST_URL}/${S3_PREFIX}/assets/files/excel/sku_stock_sample_seller.xlsx`,
}
export const FILE_UPLOAD_EXCEL_ACCEPT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const DEFAULT_PAGE_SIZE = 10
export const TRANSFER_TABLE_PAGE_SIZE = 100

export const TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
  position: ['bottomCenter'],
  defaultPageSize: DEFAULT_PAGE_SIZE,
  showSizeChanger: false,
}

export const USER_TOKEN_EXPIRE = {
  HOUR: 12,
  KEEP_LOGIN_HOUR: 24 * 30,
}

export const filenameByApi = {}

export enum DEVICE_TYPE {
  ALL = 'ALL',
  PC = 'PC',
  MOBILE = 'MOBILE',
}

export enum CLIENT_TYPE {
  WEB = 'WEB',
}

export enum CERT_TYPE {
  NEW_USER = 'NEW_USER',
  RESET_PASSWORD_BY_MOBILE_NUMBER = 'RESET_PASSWORD_BY_MOBILE_NUMBER',
  RESET_PASSWORD_BY_EMAIL = 'RESET_PASSWORD_BY_EMAIL',
  CHANGE_MOBILE_NUMBER = 'CHANGE_MOBILE_NUMBER',
  FIND_ID_BY_MOBILE_NUMBER = 'FIND_ID_BY_MOBILE_NUMBER',
}

export const DISPLAY_DEVICE_OPTIONS = [
  { label: 'PC + 모바일', value: DEVICE_TYPE.ALL },
  { label: 'PC', value: DEVICE_TYPE.PC },
  { label: '모바일', value: DEVICE_TYPE.MOBILE },
]

export enum CIRCLE_BUTTON_COLOR {
  USER_MODE_ADMIN = <any>themes.colors.blackAlpha65,
  USER_MODE_SELLER = <any>themes.colors.green,
}

export enum IMAGE_DISPLAY_TYPE {
  MAIN = 'MAIN',
  SUB = 'SUB',
  IN_EDIT = 'IN_EDIT',
}

export enum PRODUCT_PROMOTION_TYPE {
  NORMAL = 'NORMAL',
  DEAL_100 = 'DEAL_100',
  DEAL_990 = 'DEAL_990',
}

export enum FIELD_SORT_KEYS {
  FIELD_SORT_BILLED_AT_DESC = '-billedAt',
  FIELD_SORT_BRAND_APPROVED_AT = 'approvedAt',
  FIELD_SORT_BRAND_APPROVED_AT_DESC = '-approvedAt',
  FIELD_SORT_COUPON_CREATED_AT = 'createdAt',
  FIELD_SORT_COUPON_CREATED_AT_DESC = '-createdAt',
  FIELD_SORT_CREATED_AT = 'createdAt',
  FIELD_SORT_CREATED_AT_DESC = '-createdAt',
  FIELD_SORT_DISPLAY_ORDER = 'displayOrder',
  FIELD_SORT_DISPLAY_ORDER_DESC = '-displayOrder',
  FIELD_SORT_FAVORITE = 'favorite',
  FIELD_SORT_ORDER_STATUS = 'orderStatus',
  FIELD_SORT_ORDER_STATUS_DESC = '-orderStatus',
  FIELD_SORT_PRODUCT_COUNT = 'productCount',
  FIELD_SORT_PRODUCT_CREATED_AT = 'createdAt',
  FIELD_SORT_PRODUCT_CREATED_AT_DESC = '-createdAt',
  FIELD_SORT_PRODUCT_REVIEW_COUNT = 'reviewCount',
  FIELD_SORT_REQUESTED_AT = 'requestedAt',
  FIELD_SORT_REQUESTED_AT_DESC = '-requestedAt',
  FIELD_SORT_RETURN_INFO_ID = 'returnInfoId',
  FIELD_SORT_RETURN_INFO_ID_DESC = '-returnInfoId',
  FIELD_SORT_STARTED_AT = 'startedAt',
  FIELD_SORT_STARTED_AT_DESC = '-startedAt',
  FIELD_SORT_TOTAL_AMOUNT = 'totalAmount',
  FIELD_SORT_TOTAL_AMOUNT_DESC = '-totalAmount',
  FIELD_SORT_TOTAL_SOLD_AMOUNT = 'totalSoldAmount',
  FIELD_SORT_TOTAL_SOLD_AMOUNT_DESC = '-totalSoldAmount',
  FIELD_SORT_USER_CREATED_AT = 'createdAt',
  FIELD_SORT_USER_CREATED_AT_DESC = '-createdAt',
  FIELD_SORT_USER_DATE_JOINED = 'dateJoined',
  FIELD_SORT_USER_DATE_JOINED_DESC = '-dateJoined',
  FIELD_SORT_USER_LEFT_AT = 'leftAt',
  FIELD_SORT_USER_LEFT_AT_DESC = '-leftAt',
  FIELD_SORT_USER_REQUESTED_AT = 'requestedAt',
  FIELD_SORT_USER_REQUESTED_AT_DESC = '-requestedAt',
  FIELD_SORT_USER_UPDATED_AT = 'updatedAt',
  FIELD_SORT_WEIGHT = 'weight',
  FIELD_SORT_WEIGHT_DESC = '-weight',
}

export enum FIELD_SORT_VALUES {
  FIELD_SORT_BRAND_APPROVED_AT = '브랜드 승인일순',
  FIELD_SORT_BRAND_APPROVED_AT_DESC = '브랜드 승인일역순',
  FIELD_SORT_COUPON_CREATED_AT = '발급일',
  FIELD_SORT_COUPON_CREATED_AT_DESC = '발급일역순',
  FIELD_SORT_CREATED_AT = '등록일순',
  FIELD_SORT_CREATED_AT_DESC = '등록일역순',
  FIELD_SORT_DISPLAY_ORDER = '순서순',
  FIELD_SORT_DISPLAY_ORDER_DESC = '순서역순',
  FIELD_SORT_WEIGHT = '가중치순',
  FIELD_SORT_WEIGHT_DESC = '가중치역순',
  FIELD_SORT_FAVORITE = '인기순',
  FIELD_SORT_PRODUCT_COUNT = '상품 판매순',
  FIELD_SORT_PRODUCT_CREATED_AT = '상품 등록일순',
  FIELD_SORT_PRODUCT_CREATED_AT_DESC = '상품 등록일역순',
  FIELD_SORT_PRODUCT_REVIEW_COUNT = '리뷰 많은순',
  FIELD_SORT_REQUESTED_AT = '신청일순',
  FIELD_SORT_STARTED_AT = '진행일역순',
  FIELD_SORT_STARTED_AT_DESC = '진행일순',
  FIELD_SORT_TOTAL_AMOUNT = '결제금액순',
  FIELD_SORT_TOTAL_AMOUNT_DESC = '결제금액역순',
  FIELD_SORT_TOTAL_SOLD_AMOUNT = '판매가순',
  FIELD_SORT_TOTAL_SOLD_AMOUNT_DESC = '판매가역순',
  FIELD_SORT_USER_CREATED_AT = '신청일순',
  FIELD_SORT_USER_CREATED_AT_DESC = '신청일역순',
  FIELD_SORT_USER_DATE_JOINED = '가입일순',
  FIELD_SORT_USER_DATE_JOINED_DESC = '가입일역순',
  FIELD_SORT_USER_LEFT_AT = '탈퇴일순',
  FIELD_SORT_USER_LEFT_AT_DESC = '탈퇴일역순',
  FIELD_SORT_USER_REQUESTED_AT = '신청일순',
  FIELD_SORT_USER_REQUESTED_AT_DIS = '신청일역순',
  FIELD_SORT_USER_UPDATED_AT = '신청일순',
}

export enum FIELD_KEYS {
  FIELD_ACCOUNT_HOLDER_NAME = 'accountHolderName',
  FIELD_ACCOUNT_MANAGER_NAME = 'accountManagerName',
  FIELD_ACCOUNT_MANAGER_TELEPHONE_NUMBER = 'accountManagerTelephoneNumber',
  FIELD_ACCOUNT_NUMBER = 'accountNumber',
  FIELD_AD = 'ad',
  FIELD_ADDRESS = 'address',
  FIELD_AD_DISCOUNT_PRICE = 'adDiscountPrice',
  FIELD_AD_PERIOD = 'adPeriod',
  FIELD_AD_STATUS = 'adStatus',
  FIELD_AD_TYPE = 'adType',
  FIELD_AGREE_MARKETING = 'agreeMarketing',
  FIELD_AGREE_NIGHT_MARKETING = 'agreeNightMarketing',
  FIELD_AGREE_PRIVACY_POLICY = 'agreePrivacyPolicy',
  FIELD_AGREE_LOCATION_BASED_SERVICE = 'agreeLocationBasedService',
  FIELD_AGREE_THIRD_PARTY_PROVISION = 'agreeThirdPartyProvision',
  FIELD_ALL = 'total',
  FIELD_ALWAYS_GET_SHIPPING_FEE = 'alwaysGetShippingFee',
  FIELD_ANSWER_BODY = 'answerBody',
  FIELD_APPLY_REMOTE_AREA_FEE = 'applyRemoteAreaFee',
  FIELD_APPROVED_AT = 'approvedAt',
  FIELD_AS_INFO = 'asInfo',
  FIELD_AS_TELEPHONE_NUMBER = 'asTelephoneNumber',
  FIELD_ATTACHMENT = 'attachment',
  FIELD_ATTACHMENTS = 'attachments',
  FIELD_AUTHOR_WITH_DATE = 'authorWithDate',
  FIELD_BANK_BOOK_IMAGE = 'bankBookImage',
  FIELD_BANK_CODE = 'bankCode',
  FIELD_BANK_NAME = 'bankName',
  FIELD_BARCODE_NUMBER = 'barcodeNumber',
  FIELD_BASED_AT = 'basedAt',
  FIELD_BASIC = 'basic',
  FIELD_BASIC_ADDRESS = 'basicAddress',
  FIELD_BILLED_AT = 'billedAt',
  FIELD_BIRTHDAY = 'birthday',
  FIELD_BODY = 'body',
  FIELD_BRAND = 'brand',
  FIELD_BRAND_NAME = 'brandName',
  FIELD_BUSINESS_CONDITION = 'businessCondition',
  FIELD_BUSINESS_ITEM = 'businessItem',
  FIELD_BUSINESS_REGISTRATION = 'businessRegistration',
  FIELD_BUSINESS_REGISTRATION_NUMBER = 'businessRegistrationNumber',
  FIELD_CANCELED = 'canceled',
  FIELD_CANCELED_AT = 'canceledAt',
  FIELD_CANCEL_REQUESTED = 'cancelRequested',
  FIELD_CANCEL_REQUESTED_AT = 'cancelRequestedAt',
  FIELD_CAN_CONJUNCT_USE = 'canConjunctUse',
  FIELD_CATEGORIES = 'categories',
  FIELD_CATEGORY_NAME = 'categoryName',
  FIELD_CHIEF_MANAGER = 'chiefManager',
  FIELD_CHIEF_MANAGER_NAME = 'chiefManagerName',
  FIELD_CHIEF_MANAGER_USERNAME = 'chiefManagerUsername',
  FIELD_CODE = 'code',
  FIELD_COMPANY_NAME = 'companyName',
  FIELD_COMPANY_OWNER_NAME = 'companyOwnerName',
  FIELD_CONFIRM_PASSWORD = 'confirmPassword',
  FIELD_CONSUMER_NAME = 'customerName',
  FIELD_CONSUMER_PHONE_NUMBER = 'customerMobileNumber',
  FIELD_CORRESPONDENT = 'correspondent',
  FIELD_COUNT = 'count',
  FIELD_COUPON_COVERAGE_OS_TYPE = 'coverageOsType',
  FIELD_COUPON_COVERAGE_TYPE = 'coverageType',
  FIELD_COUPON_EVENT_TYPE = 'couponEventType',
  FIELD_COUPON_GROUP_NAME = 'couponGroupName',
  FIELD_COUPON_GROUP_STATUS = 'couponGroupStatus',
  FIELD_COUPON_ISSUE_COUNT = 'couponIssueCount',
  FIELD_COUPON_ISSUE_TYPE = 'couponIssueType',
  FIELD_COUPON_QUANTITY = 'couponQuantity',
  FIELD_COUPON_TYPE = 'couponType',
  FIELD_COUPON_VERIFICATION_CODE = 'code',
  FIELD_COVER_BACKGROUND_COLOR = 'coverBackgroundColor',
  FIELD_CREATED_AT = 'createdAt',
  FIELD_CUSTOMER_EMAIL = 'customerEmail',
  FIELD_CUSTOMER_PRICE = 'customerPrice',
  FIELD_DATE = 'date',
  FIELD_DATETIME = 'datetime',
  FIELD_DATE_JOINED = 'dateJoined',
  FIELD_DATE_STATUS = 'dateStatus',
  FIELD_DAY = 'day',
  FIELD_DESCRIPTION = 'description',
  FIELD_DETAILED_ADDRESS = 'detailedAddress',
  FIELD_DIRECT = 'direct',
  FIELD_DISCOUNT_PRICE = 'discountPrice',
  FIELD_DISCOUNT_RATE = 'discountRate',
  FIELD_DISPLAY_COLLECTION_TYPE = 'collectionType',
  FIELD_DISPLAY_COLLECTION_DESCRIPTION = 'description',
  FIELD_DISPLAY_COLLECTION_IMAGE = 'backgroundImage',
  FIELD_DISPLAY_DEVICE = 'displayDevice',
  FIELD_DISPLAY_ENDED_AT = 'displayEndedAt',
  FIELD_DISPLAY_ORDER = 'displayOrder',
  FIELD_DISPLAY_PERIOD = 'displayPeriod',
  FIELD_DISPLAY_STARTED_AT = 'displayStartedAt',
  FIELD_DISPLAY_TYPE = 'displayType',
  FIELD_EMAIL = 'email',
  FIELD_EMAILS = 'emails',
  FIELD_EMAIL_ID = 'emailId',
  FIELD_ENDED_AT = 'endedAt',
  FIELD_EXCEL = 'excel',
  FIELD_EXCEL_FILE_PASSWORD = 'excelFilePassword',
  FIELD_EXCEL_ROW_COUNT = 'excelRowCount',
  FIELD_EXCHANGE_CONFIRMED = 'exchangeConfirmed',
  FIELD_EXCHANGE_REQUESTED = 'exchangeRequested',
  FIELD_EXCHANGE_UNDER_RETURN = 'exchangeUnderReturn',
  FIELD_FALSE = 'false',
  FIELD_FEE_PRICE = 'feePrice',
  FIELD_FIFTY_YEAR = 'fiftyYear',
  FIELD_FITPET_FEE_RATE = 'fitpetFeeRate',
  FIELD_FREE_SHIPPING_BASE_AMOUNT = 'freeShippingBaseAmount',
  FIELD_GENDER = 'gender',
  FIELD_GLOBAL_OPTION = 'globalOption',
  FIELD_GRADE = 'grade',
  FIELD_GRADES = 'grades',
  FIELD_GUIDE = 'guide',
  FIELD_HAS_SELLING_PERIOD = 'hasSellingPeriod',
  FIELD_ID = 'id',
  FIELD_IMAGE = 'image',
  FIELD_IMAGES = 'images',
  FIELD_INACTIVATED_AT = 'inactivatedAt',
  FIELD_INACTIVE_USER_EMAIL = 'inactiveUserEmail',
  FIELD_INACTIVE_USER_MOBILE_NUMBER = 'inactiveUserMobileNumber',
  FIELD_INACTIVE_USER_NAME = 'inactiveUserName',
  FIELD_INACTIVE_USER_USERNAME = 'inactiveUserUsername',
  FIELD_ISSUE_ENDED_AT = 'issueEndedAt',
  FIELD_ISSUE_STARTED_AT = 'issueStartedAt',
  FIELD_ISSUE_STATUS = 'issueStatus',
  FIELD_ISSUE_TYPE = 'issueType',
  FIELD_IS_ACTIVE = 'isActive',
  FIELD_IS_ALWAYS = 'isAlways',
  FIELD_IS_ALWAYS_DISPLAY = 'isAlwaysDisplay',
  FIELD_IS_ANSWERED = 'isAnswered',
  FIELD_IS_CERT_NUMBER = 'isCertNumber',
  FIELD_IS_FINISHED = 'isFinished',
  FIELD_IS_IMPORTANT = 'isImportant',
  FIELD_IS_MARKETING = 'isMarketing',
  FIELD_IS_PACKAGE = 'isPackage',
  FIELD_IS_PHOTO_REVIEW = 'isPhotoReview',
  FIELD_IS_PLUS = 'isPlus',
  FIELD_IS_SELLER = 'isSeller',
  FIELD_IS_SHOWN = 'isShown',
  FIELD_IS_SOLD_OUT = 'isRealSoldOut',
  FIELD_IS_STAFF = 'isStaff',
  FIELD_IS_USE = 'isUse',
  FIELD_IS_USED = 'isUsed',
  FIELD_KIND = 'kind',
  FIELD_LAST_LOGIN = 'lastLogin',
  FIELD_LEFT_AT = 'leftAt',
  FIELD_LINK = 'link',
  FIELD_LIST_IMAGE = 'listImage',
  FIELD_MAIL_ORDER_REPORT_NUMBER = 'mailOrderReportNumber',
  FIELD_MAIL_ORDER_REPORT_REGISTRATION = 'mailOrderReportRegistration',
  FIELD_MARGIN_RATE = 'marginRate',
  FIELD_MANUAL = 'manual',
  FIELD_MAX_ORDER_COUNT = 'maxOrderCount',
  FIELD_MEMO = 'memo',
  FIELD_MESSAGE = 'message',
  FIELD_MILEAGE_POINT = 'mileagePoint',
  FIELD_MILEAGE_REWARD_RATE = 'mileageRewardRate',
  FIELD_MILEAGE_TYPE = 'mileageType',
  FIELD_MIN_ORDER_AMOUNT = 'minOrderAmount',
  FIELD_MOBILE_CONFIRM_NUMBER = 'mobileConfirmNumber',
  FIELD_MOBILE_IMAGE = 'mobileImage',
  FIELD_MOBILE_NUMBER = 'mobileNumber',
  FIELD_MOBILE_NUMBERS = 'mobileNumbers',
  FIELD_MOBILE_SUB_TITLE = 'mobileSubtitle',
  FIELD_MOBILE_TITLE = 'mobileTitle',
  FIELD_MODIFIED_AT = 'modifiedAt',
  FIELD_MONTH = 'month',
  FIELD_NAME = 'name',
  FIELD_NEW_ORDER = 'newOrder',
  FIELD_NEW_PASSWORD = 'newPassword',
  FIELD_NONE = '',
  FIELD_NOTICE = 'notice',
  FIELD_ONE_MONTH = 'oneMonth',
  FIELD_ONE_WEEK = 'oneWeek',
  FIELD_ONE_YEAR = 'oneYear',
  FIELD_ORDER_AMOUNT = 'orderAmount',
  FIELD_ORDER_ITEM_NUMBER = 'orderItemNumber',
  FIELD_ORDER_NUMBER = 'orderNumber',
  FIELD_ORDER_STATUS = 'orderStatus',
  FIELD_PAPER_COUPON_CODE_TYPE = 'paperCouponCodeType',
  FIELD_PASSWORD = 'password',
  FIELD_PET_TYPE = 'petType',
  FIELD_POST_NUMBER = 'postNumber',
  FIELD_PRICE = 'price',
  FIELD_PRODUCT_CODE = 'productCode',
  FIELD_PRODUCT_NAME = 'productName',
  FIELD_PRODUCT_OPTION_PRICE = 'productOptionPrice',
  FIELD_PRODUCT_POINT = 'point',
  FIELD_PRODUCT_WEIGHT = 'weight',
  FIELD_PROMOTION_PERIOD = 'promotionPeriod',
  FIELD_PURCHASE_PRICE = 'purchasePrice',
  FIELD_QUESTION_BODY = 'questionBody',
  FIELD_READY_FOR_SHIPPING = 'readyForShipping',
  FIELD_RECIPIENT_NAME = 'recipientName',
  FIELD_RECOMMEND_EMAIL = 'recommendEmail',
  FIELD_REGISTRATION_NUMBER = 'registrationNumber',
  FIELD_REQUESTED_AT = 'requestedAt',
  FIELD_REQUEST_MESSAGE = 'requestMessage',
  FIELD_REQUEST_TYPE = 'requestType',
  FIELD_RESPONSIBILITY = 'responsibility',
  FIELD_RETURN_ADDRESS = 'returnAddress',
  FIELD_RETURN_CONFIRMED = 'returnConfirmed',
  FIELD_RETURN_CONFIRMED_AT = 'returnConfirmedAt',
  FIELD_RETURN_EXCHANGE_GUIDE = 'returnExchangeGuide',
  FIELD_RETURN_FEE = 'returnFee',
  FIELD_RETURN_INFO = 'returnInfo',
  FIELD_RETURN_INFOS = 'returnInfos',
  FIELD_RETURN_REQUESTED = 'returnRequested',
  FIELD_RETURN_REQUESTED_AT = 'returnRequestedAt',
  FIELD_RETURN_RETURNED = 'returnReturned',
  FIELD_RETURN_SHIPPING_FEE = 'returnShippingFee',
  FIELD_RETURN_UNDER_RETURN = 'returnUnderReturn',
  FIELD_REVIEW_BODY = 'reviewBody',
  FIELD_REVIEW_GROUP = 'reviewGroup',
  FIELD_SEARCH = 'search',
  FIELD_SELLER = 'seller',
  FIELD_SELLER_NAME = 'sellerName',
  FIELD_SELLER_SHOP_NAME = 'sellerShopName',
  FIELD_SELLING = 'selling',
  FIELD_MILEAGE_RATE = 'mileageRate',
  FIELD_SELLING_PERIOD = 'sellingPeriod',
  FIELD_SEND_AT = 'sendAt',
  FIELD_SENT_AT = 'sentAt',
  FIELD_SERIES = 'series',
  FIELD_SHIPPED = 'shipped',
  FIELD_SHIPPING = 'shipping',
  FIELD_SHIPPING_COMPANY = 'shippingCompany',
  FIELD_SHIPPING_FEE = 'shippingFee',
  FIELD_SHIPPING_GUIDE = 'shippingGuide',
  FIELD_SHIPPING_NOTICE = 'shippingNotice',
  FIELD_SHIPPING_NUMBER = 'shippingNumber',
  FIELD_SHIPPING_DEADLINE = 'shippingDeadline',
  FIELD_SHIPPING_PRESET = 'shippingPreset',
  FIELD_SHOP_NAME = 'shopName',
  FIELD_SIX_MONTH = 'sixMonth',
  FIELD_SKUS = 'skus',
  FIELD_SKU_CODE = 'skuCode',
  FIELD_SLUG = 'slug',
  FIELD_SOLD_OUT = 'isRealSoldOut',
  FIELD_STARTED_AT = 'startedAt',
  FIELD_STATUS = 'status',
  FIELD_STOCK_QUANTITY = 'stockQuantity',
  FIELD_STYLE = 'style',
  FIELD_SUBJECT = 'subject',
  FIELD_SUPPLY_TYPE = 'supplyType',
  FIELD_TAX_EMAIL = 'taxEmail',
  FIELD_TELEPHONE_NUMBER = 'telephoneNumber',
  FIELD_TEXT = 'text',
  FIELD_THREE_MONTH = 'threeMonth',
  FIELD_TITLE = 'title',
  FIELD_TODAY = 'today',
  FIELD_TOTAL = 'total',
  FIELD_TREE_NODE_LABEL = 'treeNodeLabel',
  FIELD_TRUE = 'true',
  FIELD_TWO_MONTH = 'twoMonth',
  FIELD_UPDATE_APPROVED_AT = 'updateApprovedAt',
  FIELD_URL = 'url',
  FIELD_USERNAME = 'username',
  FIELD_USER_NUMBER = 'userNumber',
  FIELD_USER_CERTIFICATION = 'userCertification',
  FIELD_USER_EMAIL = 'userEmail',
  FIELD_USER_ID = 'userId',
  FIELD_USER_MOBILE_NUMBER = 'userMobileNumber',
  FIELD_USER_NAME = 'userName',
  FIELD_USER_STATUS = 'userStatus',
  FIELD_USE_OPTION = 'useOption',
  FIELD_USING_ENDED_AT = 'usingEndedAt',
  FIELD_USING_STARTED_AT = 'usingStartedAt',
  FIELD_WAREHOUSE_ADDRESS = 'warehouseAddress',
  FIELD_YEAR = 'year',
  FIELD_IS_APPROVED = 'isApproved',
  FIELD_SEARCH_KEYWORDS = 'searchKeywords',
  FIELD_REJECTED_MESSAGE = 'rejectedMessage',
  FIELD_REVIEW_MILEAGE_REWARD_RATE = 'reviewMileageRewardRate',
  FIELD_IMAGE_REVIEW_MILEAGE_REWARD_RATE = 'imageReviewMileageRewardRate',
  FIELD_CONFIRM_MILEAGE_REWARD_RATE = 'confirmMileageRewardRate',
  FIELD_SELLER_BILLED_AT = 'sellerBilledAt',

  FIELD_MEMBERSHIP_INITIAL_SUBSCRIPTION = 'membershipFirstSubscribedAt',
  FIELD_MEMBERSHIP_LAST_TERMINATION_DATE = 'membershipLastUnsubscribedAt',
  FIELD_MEMBERSHIP_START_DATE_OF_THIS_SESSION = 'membershipStartedAt',
  FIELD_MEMBERSHIP_SCHEDULED_END_DATE_FOR_THIS_SESSION = 'membershipEndedAt',
  FIELD_MEMBERSHIP_REFUND_STATUS = 'isMembershipRefundFail',
  FIELD_IS_MEMBERSHIP = 'membershipStatus',
}

export enum FIELD_VALUES {
  FIELD_ACCOUNT_HOLDER_NAME = '예금주명',
  FIELD_ACCOUNT_MANAGER_NAME = '정산 담당자명',
  FIELD_ACCOUNT_MANAGER_TELEPHONE_NUMBER = '정산 담당자 연락처',
  FIELD_ACCOUNT_NUMBER = '계좌번호',
  FIELD_ADMINISTRATOR = '관리자',
  FIELD_AD_EVENT_DESCRIPTION = '이벤트 설명',
  FIELD_AD_EVENT_NAME = '이벤트명',
  FIELD_AD_EVENT_SLUG = 'URL 슬러그',
  FIELD_AD_PRODUCT_INFOS_NAME = '섹션명',
  FIELD_AGREE = '동의',
  FIELD_ALL = '전체',
  FIELD_ALWAYS_GET_SHIPPING_FEE = '배송비 항상 받음',
  FIELD_SHIPPING_DEADLINE = '당일 출고 마감시간',
  FIELD_ANSWER_BODY = '답변내용',
  FIELD_APPLY_REMOTE_AREA_FEE = '도서 산간 지역 배송비 적용',
  FIELD_AS_TELEPHONE_NUMBER = '고객 센터 번호',
  FIELD_ATTACHMENT = '첨부파일',
  FIELD_AUTHOR_WITH_DATE = '등록일/작성자',
  FIELD_BANK_BOOK_IMAGE = '통장 사본',
  FIELD_BANK_CODE = '은행',
  FIELD_BANK_NAME = '은행',
  FIELD_BANNER_LINK = '배너 링크 주소',
  FIELD_BANNER_MOBILE_IMAGE = '이미지(모바일)',
  FIELD_BANNER_NAME = '배너명',
  FIELD_BARCODE_NUMBER = '바코드 번호',
  FIELD_BASED_AT = '결제기준일',
  FIELD_BILLED_AT = '정산확정일',
  FIELD_BILLED_AT_MONTH = '정산확정월',
  FIELD_BIRTHDAY = '생일',
  FIELD_BODY = '내용',
  FIELD_BRAND_NAME = '브랜드명',
  FIELD_BUSINESS_CONDITION = '업태',
  FIELD_BUSINESS_ITEM = '업종',
  FIELD_BUSINESS_REGISTRATION = '사업자등록증',
  FIELD_BUSINESS_REGISTRATION_NUMBER = '사업자등록번호',
  FIELD_CANCELED = '취소완료',
  FIELD_CANCELED_AT = '취소 완료일',
  FIELD_CANCEL_REQUESTED = '취소요청',
  FIELD_CATEGORY_NAME = '카테고리 이름',
  FIELD_CHIEF_MANAGER_ID = '통합매니저ID',
  FIELD_CHIEF_MANAGER_NAME = '통합매니저명',
  FIELD_COMPANY_NAME = '사업자명',
  FIELD_COMPANY_OWNER_NAME = '대표자명',
  FIELD_CONFIRM_EXCEL_FILE_PASSWORD = '엑셀 파일 비밀번호 확인',
  FIELD_CONSUMER_NAME = '구매자명',
  FIELD_CONSUMER_PHONE_NUMBER = '구매자 연락처',
  FIELD_CORRESPONDENT = '거래처',
  FIELD_COUPON_COVERAGE_OS_TYPE = '사용 범위',
  FIELD_COUPON_EVENT_TYPE = '이벤트 종류',
  FIELD_COUPON_GROUP_NAME = '쿠폰명',
  FIELD_COUPON_GROUP_STATUS = '쿠폰 그룹 상태',
  FIELD_COUPON_ISSUE_COUNT = '쿠폰 발급수',
  FIELD_COUPON_ISSUE_TYPE = '쿠폰 발급 종류',
  FIELD_COUPON_TYPE = '쿠폰 유형',
  FIELD_COUPON_VERIFICATION_CODE = '쿠폰인증번호',
  FIELD_COVER_BACKGROUND_COLOR = '커버 배경색',
  FIELD_CREATED_AT = '등록일',
  FIELD_CUSTOMER_EMAIL = '구매자ID',
  FIELD_DATE_CREATED = '등록일',
  FIELD_DATE_JOINED = '가입일',
  FIELD_DATE_STATUS = '노출상태',
  FIELD_DENY = '거부',
  FIELD_DISAGREE = '미동의',
  FIELD_DISPLAY_COLLECTION_NAME = '섹션 이름',
  FIELD_DISPLAY_COLLECTION_TYPE = '섹션 유형',
  FIELD_DISPLAY_COLLECTION_DESCRIPTION = '디스크립션',
  FIELD_DISPLAY_COLLECTION_IMAGE = '이미지',
  FIELD_DISPLAY_ORDER = '순서',
  FIELD_DISPLAY_PERIOD = '노출 기간',
  FIELD_DISPLAY_TYPE = '디스플레이 종류',
  FIELD_EMAIL = '이메일',
  FIELD_EMAIL_ID = '이메일 아이디',
  FIELD_USER_NUMBER = '회원번호',
  FIELD_END_SELLING = '판매 종료',
  FIELD_ENDED_AT = '종료일',
  FIELD_EXCEL_FILE_PASSWORD = '엑셀 파일 비밀번호',
  FIELD_EXCEL_ROW_COUNT = '엑셀 Row 수',
  FIELD_EXCHANGE_CONFIRMED = '교환반품완료',
  FIELD_EXCHANGE_REQUESTED = '교환요청',
  FIELD_EXCHANGE_UNDER_RETURN = '교환수거중',
  FIELD_FEE_PRICE = '수수료액',
  FIELD_FEMALE = '여성',
  FIELD_FIFTY_YEAR = '전체',
  FIELD_FITPET_FEE_RATE = '판매수수료',
  FIELD_FREE_SHIPPING_BASE_AMOUNT = '무료배송 최소주문금액',
  FIELD_GENDER = '성별',
  FIELD_GRADE = '등급',
  FIELD_INACTIVATED_AT = '휴면 전환일',
  FIELD_ISSUE_ENDED_AT = '발급 종료일',
  FIELD_ISSUE_STARTED_AT = '발급 시작일',
  FIELD_ISSUE_STATUS = '발급 상태',
  FIELD_IS_ANSWERED = '답변 구분',
  FIELD_IS_FINISHED = '노출 여부',
  FIELD_IS_IMPORTANT = '중요',
  FIELD_IS_PHOTO_REVIEW = '리뷰 구분',
  FIELD_IS_SHOWN = '게시 상태',
  FIELD_IS_SOLD_OUT = '품절 여부',
  FIELD_IS_USED = '사용상태',
  FIELD_IS_PACKAGE = '묶음배송가능 여부',
  FIELD_KIND = '구분',
  FIELD_LAST_LOGIN = '최종 로그인',
  FIELD_LEFT_AT = '탈퇴일',
  FIELD_MAIL_ORDER_REPORT_NUMBER = '통신판매업신고번호',
  FIELD_MAIL_ORDER_REPORT_REGISTRATION = '통신판매업신고증',
  FIELD_MALE = '남성',
  FIELD_MAX_ORDER_COUNT = '주문 제한 개수',
  FIELD_MILEAGE_PAYMENT = '지급/차감 구분',
  FIELD_MILEAGE_REWARD_RATE = '적립금 지급률',
  FIELD_MILEAGE_TYPE = '지급/차감 사유',
  FIELD_MIN_ORDER_AMOUNT = '최소 주문 금액',
  FIELD_MARGIN_RATE = '마진율',
  FIELD_MOBILE_NUMBER = '휴대폰 번호',
  FIELD_MOBILE_SUB_TITLE = '서브 타이틀(모바일)',
  FIELD_MOBILE_TITLE = '타이틀(모바일)',
  FIELD_NAME = '이름',
  FIELD_NEW_ORDER = '신규주문',
  FIELD_NEW_PASSWORD = '새로운 비밀번호',
  FIELD_NONE = '전체',
  FIELD_NOTIFICATION = '알림 설정',
  FIELD_NOT_SHOWN = '노출 안함',
  FIELD_NOT_SOLD_OUT = '품절 아님',
  FIELD_NO_ADMINISTRATOR = '관리자 아님',
  FIELD_NO_SELLER = '셀러 아님',
  FIELD_ONE_MONTH = '1개월',
  FIELD_ONE_WEEK = '1주일',
  FIELD_ONE_YEAR = '1년',
  FIELD_ORDER_CANCEL_REQUESTED_AT = '취소 요청일',
  FIELD_ORDER_CREATED_AT = '주문일',
  FIELD_ORDER_ITEM_NUMBER = '상품주문번호',
  FIELD_ORDER_NUMBER = '주문번호',
  FIELD_ORDER_RETURN_CONFIRMED_AT = '반품 완료일',
  FIELD_ORDER_RETURN_REQUESTED_AT = '반품요청일',
  FIELD_ORDER_STATUS = '주문상태',
  FIELD_PASSWORD = '비밀번호',
  FIELD_PET_TYPE = '반려동물 종류',
  FIELD_PRE_SELLING = '판매 대기',
  FIELD_PRODUCT_CODE = '상품코드',
  FIELD_PRODUCT_NAME = '상품명',
  FIELD_PRODUCT_OPTION_PRICE = '옵션가',
  FIELD_PRODUCT_POINT = '상품 판매 점수',
  FIELD_PRODUCT_STATUS = '판매 상태',
  FIELD_PRODUCT_WEIGHT = '상품 가중치',
  FIELD_PROMOTION_PERIOD = '할인 기간',
  FIELD_PURCHASE_PRICE = '매입가',
  FIELD_QUESTION_BODY = '문의내용',
  FIELD_READY_FOR_SHIPPING = '배송준비중',
  FIELD_RECIPIENT_NAME = '수취인명',
  FIELD_RECOMMEND_EMAIL = '추천인 ID',
  FIELD_REGISTRATION_NUMBER = '주민등록번호',
  FIELD_REQUESTER_NAME = '신청자명',
  FIELD_REQUEST_DATE = '신청일',
  FIELD_REQUEST_MESSAGE = '기타 사유',
  FIELD_RETURN_ADDRESS = '반품지',
  FIELD_RETURN_CONFIRMED = '반품완료',
  FIELD_RETURN_EXCHANGE_GUIDE = '교환/환불 정책',
  FIELD_RETURN_FEE = '반품 배송비',
  FIELD_RETURN_REQUESTED = '반품요청',
  FIELD_RETURN_RETURNED = '환불처리중',
  FIELD_RETURN_UNDER_RETURN = '반품수거중',
  FIELD_REVIEW_BODY = '내용',
  FIELD_REVIEW_GROUP = '리뷰그룹번호',
  FIELD_SEARCH = '제목+내용',
  FIELD_SELLER = '셀러',
  FIELD_SELLER_ADDRESS = '사업장 소재지',
  FIELD_SELLER_NAME = '셀러명',
  FIELD_SELLER_REGISTRATION_DATE = '셀러 등록일',
  FIELD_SELLING = '판매중',
  FIELD_SENT_AT = '배송 시작일',
  FIELD_SET_IS_ANSWERED = '답변 완료',
  FIELD_SET_IS_NOT_ANSWERED = '미답변',
  FIELD_SET_IS_NOT_PHOTO_REVIEW = '일반 리뷰',
  FIELD_SET_IS_PHOTO_REVIEW = '포토 리뷰',
  FIELD_SHIPPED = '배송완료',
  FIELD_SHIPPING = '배송중',
  FIELD_SHIPPING_COMPANY_NAME = '택배사',
  FIELD_SHIPPING_FEE = '기본 배송비',
  FIELD_SHIPPING_GUIDE = '배송 안내',
  FIELD_SHIPPING_NOTICE = '배송 공지',
  FIELD_SHIPPING_NUMBER = '송장번호',
  FIELD_SHIPPING_PRESET_NAME = '배송 세트명',
  FIELD_SHOP_NAME = '셀러명',
  FIELD_SHOWN = '노출',
  FIELD_SHOWN_HOME_PERIOD = '진행 기간',
  FIELD_SIX_MONTH = '6개월',
  FIELD_SKU_CODE = 'SKU 코드',
  FIELD_SKU_NAME = 'SKU 이름',
  FIELD_SOLD_OUT = '품절',
  FIELD_STARTED_AT = '시작일',
  FIELD_STATUS_OPERATION = '운영 상태',
  FIELD_STATUS_APPROVED = '승인',
  FIELD_STATUS_SUSPEND = '중지',
  FIELD_STATUS_REQUEST = '승인 요청',
  FIELD_STATUS_UPDATE_REQUEST = '변경 요청',
  FIELD_STATUS_REJECTED = '반려',
  FIELD_STATUS_INCOMPLETE = '미완료',
  FIELD_STOCK_QUANTITY = '재고',
  FIELD_STYLE = '디자인',
  FIELD_SUBJECT = '제목',
  FIELD_TIME_SALE = '타임세일',
  FIELD_TAX_EMAIL = '세금계산서 발행 이메일',
  FIELD_TELEPHONE_NUMBER = '대표 전화 번호',
  FIELD_TEXT = '내용',
  FIELD_THREE_MONTH = '3개월',
  FIELD_TITLE = '제목',
  FIELD_TODAY = '오늘',
  FIELD_TWO_MONTH = '2개월',
  FIELD_UNKNOWN = '선택하지 않음',
  FIELD_UPDATE_APPROVED_AT = '수정일',
  FIELD_USERNAME = '유저ID',
  FIELD_USER_ID = '고객번호',
  FIELD_ID = '회원 번호',
  FIELD_USING_ENDED_AT = '사용 종료일',
  FIELD_USING_STARTED_AT = '사용 시작일',
  FIELD_WAREHOUSE_ADDRESS = '출고지',
  FIELD_WEIGHT = '가중치',
  FIELD_WRITER_EMAIL = '작성자',
  FIELD_WRITER_NAME = '작성자명',
  FIELD_SEARCH_KEYWORDS = '검색키워드',
  FIELD_SUPPLY_TYPE = '상품 타입',
  FIELD_REVIEW_MILEAGE_REWARD_RATE = '리뷰 마일리지 적립율',
  FIELD_IMAGE_REVIEW_MILEAGE_REWARD_RATE = '이미지리뷰 마일리지 적립율',
  FIELD_CONFIRM_MILEAGE_REWARD_RATE = '구매확정 마일리지 적립율',
}

export enum DATE_OPTION_VALUE {
  TODAY = FIELD_KEYS.FIELD_TODAY,
  ONE_WEEK = FIELD_KEYS.FIELD_ONE_WEEK,
  ONE_MONTH = FIELD_KEYS.FIELD_ONE_MONTH,
  TWO_MONTH = FIELD_KEYS.FIELD_TWO_MONTH,
  THREE_MONTH = FIELD_KEYS.FIELD_THREE_MONTH,
  SIX_MONTH = FIELD_KEYS.FIELD_SIX_MONTH,
  ONE_YEAR = FIELD_KEYS.FIELD_ONE_YEAR,
  ALL = FIELD_KEYS.FIELD_ALL,
}

export enum ERROR_CODE {
  ERROR_USER_INFO_DOES_NOT_EXISTS = 'ERROR_USER_INFO_DOES_NOT_EXISTS',
  VALIDATION_ERROR_AUTHENTICATION_EXPIRED = 'VALIDATION_ERROR_AUTHENTICATION_EXPIRED',
  WRONG_CERTIFICATION_CODE = 'WRONG_CERTIFICATION_CODE',
}
