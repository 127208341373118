import { MutableRefObject } from 'react'
import { message } from 'antd'

import { MButton } from 'components/@atomics'
import { IModelProduct } from 'containers/models/modelProduct'
import { ModalConfirmTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'

const ButtonApproveRequests = ({
  selected,
  refetchRef,
  isRequested,
  statusFilterRefetchRef,
}: {
  selected: MutableRefObject<IModelProduct[]>
  refetchRef: MutableRefObject<Function | null>
  isRequested?: boolean
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}) => {
  const { useModalProps } = useModal()
  return (
    <>
      <MButton
        type="primary"
        onClick={() => {
          const selectedLength: number = selected.current.length
          if (!selectedLength) {
            message.warning('선택된 상품이 없습니다.')
          } else {
            useModalProps.toggleModal()
            useModalProps.setData({ products: selected.current })
          }
        }}
        width={100}
      >
        {isRequested ? '선택 승인' : '판매 재개'}
      </MButton>
      <ModalConfirmTable
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'approveRequests',
            variableKey: isRequested ? 'productApproveRequests' : 'productUnsuspendRequests',
          },
          title: isRequested ? '상품 신청 승인' : '상품 판매 재개',
          description: isRequested
            ? '선택한 상품들의 상품등록을 승인합니다.'
            : '선택한 상품들의 상품등록을 재개합니다.',
          componentOption: {
            tableComponentKey: 'tableProductDefault',
          },
        }}
        onAPISuccess={() => {
          const msg = isRequested ? '상품 등록이 승인되었습니다.' : '상품 판매가 재개되었습니다.'
          message.success(msg)
          if (refetchRef.current) {
            refetchRef.current()
          }
          if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
            statusFilterRefetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonApproveRequests
