import { BannerType } from 'containers/hooks/useBanner'

export const YES_OR_NO_TEXT = {
  YES: '네',
  NO: '아니요',
}

export enum USER_GENDER {
  UNKNOWN = 'UNKNOWN',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum USER_MODE_TYPE {
  ADMIN = 'USER_MODE_ADMIN',
  SELLER = 'USER_MODE_SELLER',
  CUSTOMER = 'USER_MODE_CUSTOMER',
}

export const USER_MODE = {
  ADMIN: USER_MODE_TYPE.ADMIN,
  SELLER: USER_MODE_TYPE.SELLER,
  CUSTOMER: USER_MODE_TYPE.CUSTOMER,
}

export enum SUPPLY_TYPE {
  PB = 'PB',
  CONSIGNMENT = 'CONSIGNMENT',
  BUY = 'BUY',
  NONE = 'NONE',
}

export enum SUPPLY_TYPE_TEXT {
  PB = 'PB',
  CONSIGNMENT = '위탁',
  BUY = '사입',
  NONE = '없음',
}

export enum PET_TYPE {
  ALL = 'ALL',
  CAT = 'CAT',
  DOG = 'DOG',
}

export enum PET_TYPE_TEXT {
  ALL = '강아지/고양이',
  CAT = '고양이',
  DOG = '강아지',
}

export enum PAYMENT_TYPE {
  BANK = 'BANK',
  CARD = 'CARD',
  CASH = 'CASH',
  MOBILE = 'MOBILE',
  V_BANK = 'VBANK',
  ETC = 'ETC',
}

export enum TRANSFER_TYPE {
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  PRODUCT_EVENT = 'PRODUCT_EVENT',
  SELLER = 'SELLER',
  SKU = 'SKU',
  USER = 'USER',
}

export enum PRODUCT_OPTION_TYPE {
  SEPARATION = 'SEPARATION',
  COMBINATION = 'COMBINATION',
  LOAD_EXISTING_PRODUCT_OPTION = 'LOAD_EXISTING_PRODUCT_OPTION',
}

export enum ORDER_STATUS_TYPE {
  CANCELED = 'CANCELED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CONFIRMED = 'CONFIRMED',
  NEW_ORDER = 'NEW_ORDER',
  READY_FOR_SHIPPING = 'READY_FOR_SHIPPING',
  RETURN_CONFIRMED = 'RETURN_CONFIRMED',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
  RETURN_RETURNED = 'RETURN_RETURNED',
  RETURN_UNDER_RETURN = 'RETURN_UNDER_RETURN',
  EXCHANGE_REQUESTED = 'EXCHANGE_REQUESTED',
  EXCHANGE_UNDER_RETURN = 'EXCHANGE_UNDER_RETURN',
  EXCHANGE_CONFIRMED = 'EXCHANGE_CONFIRMED',
  SHIPPED = 'SHIPPED',
  SHIPPING = 'SHIPPING',
  UNCONFIRMED_PAYMENT = 'UNCONFIRMED_PAYMENT',
}

export enum ORDER_STATUS_TEXT {
  CANCELED = '취소완료',
  CANCEL_REQUESTED = '취소요청',
  CONFIRMED = '구매확정',
  NEW_ORDER = '신규주문',
  READY_FOR_SHIPPING = '배송준비중',
  RETURN_CONFIRMED = '반품 완료',
  RETURN_REQUESTED = '반품 요청',
  RETURN_RETURNED = '수거 완료 (환불 진행 중)',
  RETURN_UNDER_RETURN = '반품 수거중',
  SHIPPED = '배송완료',
  SHIPPING = '배송중',
  UNCONFIRMED_PAYMENT = '미결제',
}

export enum RETURN_RESPONSIBILITY_TYPE {
  ALL = 'ALL',
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  SELLER = 'SELLER',
}

export enum RETURN_RESPONSIBILITY_TYPE_TEXT {
  ALL = '전체',
  ADMIN = '관리자',
  CUSTOMER = '구매자',
  SELLER = '판매자',
}

export enum TRANSACTION_PROCESS_STATUS {
  READY = 'READY',
  REQ_CERTIFICATE = 'REQ_CERTIFICATE',
  RES_APPROVED = 'RES_APPROVED',
  RES_CERTIFICATE = 'RES_CERTIFICATE',
  VBANK_APPROVED = 'VBANK_APPROVED',
}

export enum COUPON_STATUS_TEXT {
  UNUSED = '사용가능',
  USED = '주문사용',
  EXPIRED = '사용만료',
}

export enum COUPON_STATUS {
  UNUSED = 'UNUSED',
  USED = 'USED',
  EXPIRED = 'EXPIRED',
}

export enum SERVICE_QNA_CATEGORY_TEXT {
  ORDER = '주문/결제',
  SHIPPING = '배송',
  REFUND = '반품/환불',
  EVENT_PLAN = '이벤트',
  MEMBER_INFO = '회원정보',
  ORDER_ETC = '주문 기타',
  ETC = '기타',
}

export const STATUS = {
  REQUEST: 'REQUEST',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  EDIT: 'EDIT',
  SUSPEND: 'SUSPEND',
  BANNED: 'BANNED',
  INCOMPLETE: 'INCOMPLETE',
}

export const STATUS_REQUEST_TYPE = {
  BRAND_REGISTRATION: 'BRAND_REGISTRATION',
  BRAND_UPDATE: 'BRAND_UPDATE',
  CAMPAIGN_REGISTRATION: 'CAMPAIGN_REGISTRATION',
  INFLUENCER_ROLE: 'INFLUENCER_ROLE',
  PRODUCT_REGISTRATION: 'PRODUCT_REGISTRATION',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  SELLER_ROLE: 'SELLER_ROLE',
  SELLER_UPDATE: 'SELLER_UPDATE',
}

export enum COUPON_GROUP_STATUS_TEXT {
  REGISTERED = 'REGISTERED',
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
}

export enum COUPON_GROUP_STATUS_TYPE {
  REGISTERED = 'REGISTERED',
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
}

export enum COUPON_GROUP_BENEFIT_TEXT {
  DISCOUNT = 'DISCOUNT',
  REWARD = 'REWARD',
}

export enum COUPON_GROUP_BENEFIT_TYPE {
  DISCOUNT = 'DISCOUNT',
  REWARD = 'REWARD',
}

export enum COUPON_GROUP_DISCOUNT_TYPE {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export enum PAPER_COUPON_CODE_TYPE {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum COUPON_GROUP_PLATFORM_TYPE {
  APP = 'APP',
  MOBILE = 'MOBILE',
  PC = 'PC',
}

export enum COUPON_TYPE {
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER',
  SHIPPING = 'SHIPPING',
}

export enum COUPON_TYPE_TEXT {
  PRODUCT = '상품 쿠폰',
  ORDER = '주문 쿠폰',
  SHIPPING = '배송비 쿠폰',
}

export enum COUPON_TYPE_NAME {
  PRODUCT = '상품',
  ORDER = '주문',
  SHIPPING = '배송비',
}

export enum COUPON_ISSUE_TYPE {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  DOWNLOAD = 'DOWNLOAD',
  GRADE = 'GRADE',
  PAPER = 'PAPER',
  PAPER_SKT = 'PAPER_SKT',
  MASS = 'MASS',
}

export enum COUPON_ISSUE_TYPE_TEXT {
  AUTO = '자동 발급',
  MANUAL = '수동 발급',
  DOWNLOAD = '다운로드',
  GRADE = '등급쿠폰',
  PAPER = '페이퍼쿠폰',
  PAPER_SKT = '페이퍼쿠폰_SKT',
  MASS = '대량발급',
}

export enum COUPON_ISSUE_STATUS {
  ISSUING = 'ISSUING',
  SUSPENDED = 'SUSPENDED',
  STOPPED = 'STOPPED',
  COUNT_EXCEED = 'COUNT_EXCEED',
  NOT_ISSUABLE_TIME = 'NOT_ISSUABLE_TIME',
}

export enum COUPON_COVERAGE_OS_TYPE {
  ALL = 'ALL',
  WEB = 'WEB',
  APP = 'APP',
}

export enum COUPON_ISSUE_STATUS_TEXT {
  ISSUING = '발급 중',
  SUSPENDED = '발급 중 일지 중지',
  REGISTERING = '발급 준비중',
  STOPPED = '발급 중지',
  COUNT_EXCEED = '발급 수량 초과',
  NOT_ISSUABLE_TIME = '발급 가능 일자 아님',
  NOT_USING_TIME = '발급 사용 가능 기간 아님',
}

export enum COUPON_EVENT_TYPE {
  FIRST_ORDER = 'FIRST_ORDER',
  THANKS_ORDER = 'THANKS_ORDER',
  BIRTH = 'BIRTH',
  SIGN_UP = 'SIGN_UP',
  WAKE_UP = 'WAKE_UP',
  AGREE_MARKETING = 'AGREE_MARKETING',
  INVITE_FIRST_ORDER = 'INVITE_FIRST_ORDER',
  INVITED_FIRST_ORDER = 'INVITED_FIRST_ORDER',
}

export enum COUPON_EVENT_TYPE_TEXT {
  FIRST_ORDER = '첫 구매',
  THANKS_ORDER = '구매 감사',
  BIRTH = '생일 축하',
  SIGN_UP = '회원가입 축하',
  WAKE_UP = '휴면 해제 감사',
  AGREE_MARKETING = '마케팅 수신동의',
  INVITE_FIRST_ORDER = '친구추천 초대자 쿠폰',
  INVITED_FIRST_ORDER = '친구추천 피초대자 쿠폰',
}

export enum COUPON_COVERAGE_TYPE {
  ALL = 'ALL',
  SPECIFIC_BRAND = 'SPECIFIC_BRAND',
  SPECIFIC_CATEGORY = 'SPECIFIC_CATEGORY',
  SPECIFIC_PRODUCT = 'SPECIFIC_PRODUCT',
}

export enum COUPON_LOCATION_TYPE {
  FIELD_LOCATION_HOME = 'FIELD_LOCATION_HOME',
  FIELD_LOCATION_PRODUCT_DETAIL = 'FIELD_LOCATION_PRODUCT_DETAIL',
  FIELD_LOCATION_PRODUCT_LIST = 'FIELD_LOCATION_PRODUCT_LIST',
  FIELD_LOCATION_BRAND_TOP = 'FIELD_LOCATION_BRAND_TOP',
  FIELD_LOCATION_CATEGORY_TOP = 'FIELD_LOCATION_CATEGORY_TOP',
}

export enum NOTICE_RECEIVER_TYPE {
  CUSTOMER = 'CUSTOMER',
  SELLER = 'SELLER',
}

export enum NOTICE_RECEIVER_TYPE_TEXT {
  CUSTOMER = '회원',
  SELLER = '셀러',
}

export enum NOTICE_KIND_TYPE {
  NOTICE_KIND_GENERAL = 'NOTICE_KIND_GENERAL',
  NOTICE_KIND_BILLING = 'NOTICE_KIND_BILLING',
  NOTICE_KIND_MANUAL = 'NOTICE_KIND_MANUAL',
}

export enum NOTICE_KIND_TYPE_TEXT {
  NOTICE_KIND_BILLING = '정산',
  NOTICE_KIND_GENERAL = '일반',
  NOTICE_KIND_MANUAL = '메뉴얼',
}

export enum BRAND_STATUS_TEXT {
  APPROVED = '운영중',
  REJECTED = '등록반려됨',
  REQUEST = '운영대기',
  SUSPEND = '운영중지',
}

export enum MILEAGE_TYPE {
  ADMIN = 'ADMIN',
  AGREE_MARKETING = 'AGREE_MARKETING',
  DELETE_REVIEW = 'DELETE_REVIEW',
  ETC = 'ETC',
  EXPIRED = 'EXPIRED',
  HOSPITAL_REVIEW = 'HOSPITAL_REVIEW',
  IMAGE_REVIEW = 'IMAGE_REVIEW',
  INVITE = 'INVITE',
  INVITED = 'INVITED',
  LEAVE = 'LEAVE',
  MARKETING_PROMOTION = 'MARKETING_PROMOTION',
  MIGRATED = 'MIGRATED',
  MIGRATED_IGNORE = 'MIGRATED_IGNORE',
  ORDER = 'ORDER',
  ORDER_ITEM_CANCELED = 'ORDER_ITEM_CANCELED',
  ORDER_ITEM_CONFIRM_CANCELED = 'ORDER_ITEM_CONFIRM_CANCELED',
  RETURN_SHIPPING_FEE = 'RETURN_SHIPPING_FEE',
  SHIPPING_CANCELED = 'SHIPPING_CANCELED',
  STYLE_WAVER = 'STYLE_WAVER',
  UNUSED = 'UNUSED',
  WRITE_REVIEW = 'WRITE_REVIEW',
}

export enum MILEAGE_TYPE_TEXT {
  ADMIN = '관리자',
  AGREE_MARKETING = '마케팅 수신 동의로 회원 가입으로 적립금 적립',
  DELETE_REVIEW = '리뷰 삭제',
  ETC = '기타',
  EXPIRED = '유효기간 만료',
  HOSPITAL_REVIEW = '병원 리뷰',
  IMAGE_REVIEW = '포토 리뷰 작성',
  INVITE = '추천인으로 받음',
  INVITED = '추천인 등록',
  LEAVE = '회원탈퇴',
  MARKETING_PROMOTION = '마케팅 및 프로모션',
  MIGRATED = '고도몰 회원전환',
  MIGRATED_IGNORE = '고도몰 회원전환, 고도몰 버그로 인해 무시함',
  ORDER = '포인트 사용',
  ORDER_ITEM_CANCELED = '포인트 사용 취소',
  ORDER_ITEM_CONFIRM_CANCELED = '구매 환불',
  RETURN_SHIPPING_FEE = '반품배송비 적립금 차감',
  SHIPPING_CANCELED = '배송비 취소로 인한 적립금 반환',
  STYLE_WAVER = '스타일 웨이버 활동 지원',
  UNUSED = '미사용 포인트 제거',
  WRITE_REVIEW = '일반 리뷰 작성',
}

export enum MILEAGE_PAYMENT_TYPE_TEXT {
  PAY = '지급',
  DEDUCT = '차감',
}

export enum INFORM_PUSH_QUEUE_STATUS {
  SEND = 'SEND',
  SUCCESS = 'SUCCESS',
  TEMP = 'TEMP',
}

export const INFORM_PUSH_TARGET_TYPE_INFO_TEXT: { [key: string]: string } = {
  normal: '일반',
  ios: 'IOS',
  android: 'Android',
  etc: '기타',
  male: '남성',
  female: '여성',
}

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVATED = 'INACTIVATED',
  LEFT = 'LEFT',
  ADMIN_LEFT = 'ADMIN_LEFT',
}

export enum USER_STATUS_TEXT {
  ACTIVE = '일반',
  INACTIVATED = '휴면',
  LEFT = '탈퇴',
  ADMIN_LEFT = '관리자가 탈퇴',
}

export enum TRANSACTION_STATUS {
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  PAID = 'PAID',
  READY = 'READY',
}

export enum TRANSACTION_STATUS_TEXT {
  CANCELLED = '결제 취소',
  FAILED = '결제 실패',
  PAID = '결제 완료',
  READY = '입금 대기',
}

export enum DISPLAY_COLLECTION_TYPE {
  BEST = 'BEST',
  AD = 'AD',
  TODAY_SPECIAL_PRICE = 'TODAY_SPECIAL_PRICE',
  NEW_ARRIVALS = 'NEW_ARRIVALS',
  NORMAL = 'NORMAL',
  BEST_REVIEW = 'BEST_REVIEW',
  MAGAZINE_BACKGROUND_IMAGE = 'MAGAZINE_BACKGROUND_IMAGE',
  MAGAZINE_IMAGE = 'MAGAZINE_IMAGE',
}

export enum DISPLAY_COLLECTION_TYPE_TEXT {
  BEST = 'BEST',
  AD = '이벤트',
  TODAY_SPECIAL_PRICE = 'Today 특가',
  NEW_ARRIVALS = '따끈따끈 신상품',
  NORMAL = '일반',
  BEST_REVIEW = '베스트 리뷰',
  MAGAZINE_BACKGROUND_IMAGE = '매거진(백그라운드 이미지)',
  MAGAZINE_IMAGE = '매거진(이미지)',
}

export enum DISPLAY_TYPE {
  COVERFLOW = 'COVERFLOW',
  FLAT = 'FLAT',
  FLAT_TIME = 'FLAT_TIME',
}

export enum DISPLAY_TYPE_TEXT {
  COVERFLOW = '커버플로우',
  FLAT = '플랫',
  FLAT_TIME = '플랫타임',
}

export enum DISPLAY_STATUS {
  NOT_DISPLAY = 'NOT_DISPLAY',
  TO_BE_DISPLAY = 'TO_BE_DISPLAY',
  DISPLAY = 'DISPLAY',
  FINISHED_DISPLAY = 'FINISHED_DISPLAY',
  ALWAYS_DISPLAY = 'ALWAYS_DISPLAY',
}

export enum DISPLAY_STATUS_TEXT {
  NOT_DISPLAY = '노출 안함',
  TO_BE_DISPLAY = '노출 예정',
  DISPLAY = '노출 중',
  FINISHED_DISPLAY = '기간 종료',
  ALWAYS_DISPLAY = '상시 노출',
}

export const OPTION_COUNT_TYPESET = {
  1: '1개',
  2: '2개',
  3: '3개',
}

export enum BANNER_TYPE_KEY {
  TOP_ROLL = 'TOP_ROLL',
  HOME_MIDDLE = 'HOME_MIDDLE',
  HOME_BOTTOM = 'HOME_BOTTOM',
  // MULTI_LINE = 'MULTI_LINE',
  HOME_MULTI_LINE = 'HOME_MULTI_LINE',
  PRODUCT_TOP = 'PRODUCT_TOP',
}

export const BANNER_TYPE_DATA: { [key: string]: BannerType } = {
  BANNER_TOP_ROLL: {
    key: BANNER_TYPE_KEY.TOP_ROLL,
    query: {
      gqlKey: 'TopRoll',
      dataKey: 'topRoll',
      variableKey: BANNER_TYPE_KEY.TOP_ROLL,
    },
    display: {
      url: 'top-roll',
      bannerName: '상단 롤 배너',
      displayPosition: '랜딩',
      sort: true,
    },
  },
  BANNER_HOME_MIDDLE: {
    key: BANNER_TYPE_KEY.HOME_MIDDLE,
    query: {
      gqlKey: 'HomeMiddle',
      dataKey: 'homeMiddle',
      variableKey: BANNER_TYPE_KEY.HOME_MIDDLE,
    },
    display: {
      url: 'home-middle',
      bannerName: '중단 띠 배너',
      displayPosition: '랜딩(Main)',
      sort: true,
    },
  },
  BANNER_HOME_BOTTOM: {
    key: BANNER_TYPE_KEY.HOME_BOTTOM,
    query: {
      gqlKey: 'HomeBottom',
      dataKey: 'homeBottom',
      variableKey: BANNER_TYPE_KEY.HOME_BOTTOM,
    },
    display: {
      url: 'home-bottom',
      bannerName: '하단 띠 배너',
      displayPosition: '랜딩(Main)',
      sort: true,
    },
  },
  BANNER_PRODUCT_TOP: {
    key: BANNER_TYPE_KEY.PRODUCT_TOP,
    query: {
      gqlKey: 'ProductTop',
      dataKey: 'ProductTop',
      variableKey: BANNER_TYPE_KEY.PRODUCT_TOP,
    },
    display: {
      url: 'product-top',
      bannerName: '상품 상단',
      displayPosition: '상품 페이지(?)',
      sort: false,
    },
  },
  BANNER_MULTI_LINE: {
    key: BANNER_TYPE_KEY.HOME_MULTI_LINE,
    query: {
      gqlKey: 'HomeMultiLine',
      dataKey: 'HomeMultiLine',
      variableKey: BANNER_TYPE_KEY.HOME_MULTI_LINE,
    },
    display: {
      url: 'multi-line',
      bannerName: '홈 멀티배너',
      displayPosition: '홈 멀티배너',
      sort: false,
    },
  },
}

export enum AD_STATUS {
  AD_BEFORE = 'AD_BEFORE',
  AD_ONGOING = 'AD_ONGOING',
  AD_AFTER = 'AD_AFTER',
}

export enum AD_STATUS_TEXT {
  AD_BEFORE = '진행전',
  AD_ONGOING = '진행중',
  AD_AFTER = '종료',
}

export enum AD_TYPE {
  DEAL_100 = 'DEAL_100',
  DEAL_990 = 'DEAL_990',
  NORMAL = 'NORMAL',
}

export enum AD_TYPE_TEXT {
  DEAL_100 = '100원 딜',
  DEAL_990 = '990원 딜',
  NORMAL = '일반',
}

export enum CARD_SOURCE_CODE {
  PAYCO = 'C',
  SAMSUNG_PAY = 'B',
  SAMSUMG_PAY_CHECK = 'D',
  SSGPAY = 'G',
  KAKAOPAY = 'O',
  LPAY = 'L',
  KB_APPCARD = 'K',
  KPAY = 'A',
  TOSSPAY = 'H',
  NAVERPAY = 'I',
  CHAIPAY = 'R',
  TMONEYPAY = 'U',
}

export enum PAY_METHOD_TYPE {
  CARD = 'CARD',
  TRANS = 'TRANS',
  VBANK = 'VBANK',
  PHONE = 'PHONE',
  SAMSUNG = 'SAMSUNG',
  KPAY = 'KPAY',
  NAVERPAY = 'NAVERPAY',
  KAKAOPAY = 'KAKAOPAY',
  PAYCO = 'PAYCO',
  LPAY = 'LPAY',
  SSGPAY = 'SSGPAY',
  TOSSPAY = 'TOSSPAY',
  CULTURELAND = 'CULTURELAND',
  SMARTCULTURE = 'SMARTCULTURE',
  HAPPYMONEY = 'HAPPYMONEY',
  BOOKNLIFE = 'BOOKNLIFE',
  POINT = 'POINT',
  ZERO_PRICE = 'ZERO_PRICE',
  ADMIN = 'ADMIN',
  ETC = 'ETC',
}

export enum PAY_METHOD_TYPE_TEXT {
  CARD = '신용카드',
  TRANS = '실시간계좌이체',
  VBANK = '가상계좌',
  PHONE = '휴대폰소액결제',
  SAMSUNG = '삼성페이',
  KPAY = 'KPAY',
  NAVERPAY = '네이버페이',
  KAKAOPAY = '카카오페이',
  PAYCO = 'PAYCO',
  LPAY = 'LPAY',
  SSGPAY = 'SSG페이',
  TOSSPAY = '토스간편결제',
  CULTURELAND = '문화상품권',
  SMARTCULTURE = '스마트 문화상품권',
  HAPPYMONEY = '해피머니',
  BOOKNLIFE = '도서문화상품권',
  POINT = '포인트 결제',
  ZERO_PRICE = '할인 0원 결제',
  ADMIN = '관리자 결제 처리',
  ETC = 'ETC',
}

export enum PG_PROVIDER_TYPE {
  ALIPAY = 'ALIPAY',
  BLUEWALNUT = 'BLUEWALNUT',
  CHAI = 'CHAI',
  DANAL = 'DANAL',
  DANAL_TPAY = 'DANAL_TPAY',
  EXIMBAY = 'EXIMBAY',
  HTML5_INICIS = 'HTML5_INICIS',
  JTNET = 'JTNET',
  KAKAOPAY = 'KAKAOPAY',
  KCP = 'KCP',
  KICC = 'KICC',
  MOBILIANS = 'MOBILIANS',
  NAVERCO = 'NAVERCO',
  NAVERPAY = 'NAVERPAY',
  NICE = 'NICE',
  PAYCO = 'PAYCO',
  PAYPAL = 'PAYPAL',
  SMILEPAY = 'SMILEPAY',
  SYRUP = 'SYRUP',
  UPLUS = 'UPLUS',
}

export enum PG_PROVIDER_TYPE_TEXT {
  ALIPAY = '알리페이',
  BLUEWALNUT = '블루월넛',
  CHAI = '차이 간편결제',
  DANAL = '다날휴대폰소액결제',
  DANAL_TPAY = '다날일반결제',
  EXIMBAY = '엑심베이',
  HTML5_INICIS = '이니시스웹표준',
  JTNET = 'JTNet',
  KAKAOPAY = '카카오페이',
  KCP = 'NHN KCP',
  KICC = '한국정보통신',
  MOBILIANS = '모빌리언스 휴대폰소액결제',
  NAVERCO = '네이버페이-주문형',
  NAVERPAY = '네이버페이-결제형',
  NICE = '나이스페이',
  PAYCO = '페이코',
  PAYPAL = '페이팔',
  SMILEPAY = '스마일페이',
  SYRUP = '시럽페이',
  UPLUS = '토스페이먼츠',
}

export enum CARD_COMPANIES_CODE {
  외환 = '01',
  롯데 = '03',
  현대 = '04',
  국민 = '06',
  비씨 = '11',
  삼성 = '12',
  신한 = '14',
  글로벌VISA = '21',
  글로벌MASTER = '22',
  글로벌JCB = '23',
  글로벌아멕스 = '24',
  글로벌다이너스 = '25',
  중국은련 = '26',
  광주 = '32',
  전북 = '33',
  하나 = '34',
  산업 = '35',
  NH = '41',
  씨티 = '43',
  우리 = '44',
  신협체크 = '48',
  수협 = '51',
  제주 = '52',
  MG새마을금고체크 = '54',
  케이뱅크 = '55',
  카카오뱅크 = '56',
  우체국체크 = '71',
  저축은행체크 = '95',
}

export const getCardCompanyName = (cardCompanyCode: string) => {
  switch (cardCompanyCode) {
    case CARD_COMPANIES_CODE.외환:
      return '외환카드'
    case CARD_COMPANIES_CODE.롯데:
      return '롯데카드'
    case CARD_COMPANIES_CODE.현대:
      return '현대카드'
    case CARD_COMPANIES_CODE.국민:
      return '국민카드'
    case CARD_COMPANIES_CODE.비씨:
      return '비씨카드'
    case CARD_COMPANIES_CODE.삼성:
      return '삼성카드'
    case CARD_COMPANIES_CODE.신한:
      return '신한카드(구,LG카드 포함)'
    case CARD_COMPANIES_CODE.글로벌VISA:
      return '글로벌 VISA'
    case CARD_COMPANIES_CODE.글로벌MASTER:
      return '글로벌 MASTER'
    case CARD_COMPANIES_CODE.글로벌JCB:
      return '글로벌 JCB'
    case CARD_COMPANIES_CODE.글로벌아멕스:
      return '글로벌 아멕스'
    case CARD_COMPANIES_CODE.글로벌다이너스:
      return '글로벌 다이너스'
    case CARD_COMPANIES_CODE.중국은련:
      return '중국은련카드'
    case CARD_COMPANIES_CODE.광주:
      return '광주카드'
    case CARD_COMPANIES_CODE.전북:
      return '전북카드'
    case CARD_COMPANIES_CODE.하나:
      return '하나카드'
    case CARD_COMPANIES_CODE.산업:
      return '산업카드'
    case CARD_COMPANIES_CODE.NH:
      return 'NH카드'
    case CARD_COMPANIES_CODE.씨티:
      return '씨티카드'
    case CARD_COMPANIES_CODE.우리:
      return '우리카드'
    case CARD_COMPANIES_CODE.신협체크:
      return '신협체크카드'
    case CARD_COMPANIES_CODE.수협:
      return '수협카드'
    case CARD_COMPANIES_CODE.제주:
      return '제주카드'
    case CARD_COMPANIES_CODE.MG새마을금고체크:
      return 'MG새마을금고체크'
    case CARD_COMPANIES_CODE.케이뱅크:
      return '케이뱅크카드'
    case CARD_COMPANIES_CODE.카카오뱅크:
      return '카카오뱅크'
    case CARD_COMPANIES_CODE.우체국체크:
      return '우체국체크'
    case CARD_COMPANIES_CODE.저축은행체크:
      return '저축은행체크'
    default:
      return '-'
  }
}

export const getTransferTypeByCouponCoverageType = (coverageType: COUPON_COVERAGE_TYPE) => {
  switch (coverageType) {
    case COUPON_COVERAGE_TYPE.SPECIFIC_BRAND:
      return TRANSFER_TYPE.BRAND
    case COUPON_COVERAGE_TYPE.SPECIFIC_CATEGORY:
      return TRANSFER_TYPE.CATEGORY
    case COUPON_COVERAGE_TYPE.SPECIFIC_PRODUCT:
      return TRANSFER_TYPE.PRODUCT
    default:
      return null
  }
}
