import COMMON_MUTATION_GQLS from 'containers/gqls/common/mutations'
import PRODUCT_OPTION_QUERY_GQLS from 'containers/gqls/products/productOption/queries'
import SKU_MUTATION_GQLS from 'containers/gqls/products/sku/mutations'
import SKU_QUERY_GQLS from 'containers/gqls/products/sku/queries'
import AD_MUTATION_GQLS from 'containers/gqls/promotions/ad/mutations'
import AD_QUERY_GQLS from 'containers/gqls/promotions/ad/queries'
import BANNER_MUTATION_GQLS from 'containers/gqls/layouts/banner/mutations'
import BANNER_QUERY_GQLS from 'containers/gqls/layouts/banner/queries'
import BILLING_ITEM_QUERY_GQLS from 'containers/gqls/billings/billingItem/queries'
import BILLING_MUTATION_GQLS from 'containers/gqls/billings/billing/mutations'
import BILLING_QUERY_GQLS from 'containers/gqls/billings/billing/queries'
import BRAND_MUTATION_GQLS from 'containers/gqls/products/brand/mutations'
import BRAND_QUERY_GQLS from 'containers/gqls/products/brand/queries'
import CACHE_MUTATION_GQLS from 'containers/gqls/cache/mutations'
import CATEGORY_MUTATION_GQLS from 'containers/gqls/products/category/mutations'
import CATEGORY_QUERY_GQLS from 'containers/gqls/products/category/queries'
import COMMON_QUERY_GQLS from 'containers/gqls/common/queries'
import COUPON_GROUP_MUTATION_GQLS from 'containers/gqls/promotions/couponGroup/mutation'
import COUPON_GROUP_QUERY_GQLS from 'containers/gqls/promotions/couponGroup/queries'
import COUPON_MUTATION_GQLS from 'containers/gqls/promotions/coupon/mutation'
import CUSTOMER_GRADE_MUTATION_GQLS from 'containers/gqls/configurations/customerGrade/mutation'
import CUSTOMER_GRADE_QUERY_GQLS from 'containers/gqls/configurations/customerGrade/queries'
import DASHBOARD_QUERY_GQLS from 'containers/gqls/boards/dashboard/queries'
import DEFAULT_MUTATION_GQLS from 'containers/gqls/base/defaultMutations'
import DISPLAY_COLLECTION_MUTATION_GQLS from 'containers/gqls/layouts/displayCollection/mutations'
import DISPLAY_COLLECTION_QUERY_GQLS from 'containers/gqls/layouts/displayCollection/queries'
import DISPLAY_PRODUCT_INFO_MUTATION_GQLS from 'containers/gqls/layouts/displayProductInfo/mutations'
import DISPLAY_PRODUCT_INFO_QUERY_GQLS from 'containers/gqls/layouts/displayProductInfo/queries'
import EXCEL_REQUEST_QUERY_GQLS from 'containers/gqls/etc/excelRequest/queries'
import INFORM_MUTATION_GQLS from 'containers/gqls/accounts/inform/mutations'
import MILEAGE_MUTATION_GQLS from 'containers/gqls/accounts/mileage/mutation'
import MILEAGE_QUERY_GQLS from 'containers/gqls/accounts/mileage/queries'
import NOTICE_QUERY_GQLS from 'containers/gqls/configurations/notice/queries'
import ORDER_QUERY_GQLS from 'containers/gqls/orders/order/queries'
import PRODUCT_MUTATION_GQLS from 'containers/gqls/products/product/mutations'
import PRODUCT_QNA_MUTATION_GQLS from 'containers/gqls/boards/qna/productQna/mutations'
import PRODUCT_QNA_QUERY_GQLS from 'containers/gqls/boards/qna/productQna/queries'
import PRODUCT_QUERY_GQLS from 'containers/gqls/products/product/queries'
import PRODUCT_PROMOTION_QUERY_GQLS from 'containers/gqls/promotions/productPromotion/queries'
import PRODUCT_PROMOTION_MUTATION_GQLS from 'containers/gqls/promotions/productPromotion/mutations'
import PUSH_MUTATION_GQLS from 'containers/gqls/promotions/push/mutations'
import REVIEW_MUTATION_GQLS from 'containers/gqls/boards/review/mutations'
import REVIEW_QUERY_GQLS from 'containers/gqls/boards/review/queries'
import SELLER_MUTATION_GQLS from 'containers/gqls/accounts/seller/mutations'
import SELLER_QUERY_GQLS from 'containers/gqls/accounts/seller/queries'
import SHIPPING_MUTATION_GQLS from 'containers/gqls/orders/shipping/mutations'
import SHIPPING_PRESET_MUTATION_GQLS from 'containers/gqls/orders/shippingPreset/mutations'
import STATUS_MUTATION_GQLS from 'containers/gqls/accounts/status/mutations'
import USER_MUTATION_GQLS from 'containers/gqls/accounts/user/mutations'
import USER_QUERY_GQLS from 'containers/gqls/accounts/user/queries'
import { DEFAULT_QUERY_GQLS } from 'containers/gqls/base/defaultQueries'
import ORDER_MUTATION_GQLS from 'containers/gqls/orders/order/mutations'
import NOTICE_MUTATION_GQLS from 'containers/gqls/configurations/notice/mutations'
import APP_VERSION_QUERY_GQLS from 'containers/gqls/etc/appVersion/queries'
import APP_VERSION_MUTATION_GQLS from 'containers/gqls/etc/appVersion/mutations'
import EXCEL_REQUEST_MUTATION_GQLS from 'containers/gqls/etc/excelRequest/mutations'
import memberships from 'graphql/gqls/membership/queries'

export const QUERY_KEY2GQLS = {
  ad: AD_QUERY_GQLS.AD,
  ads: AD_QUERY_GQLS.ADS,
  banner: BANNER_QUERY_GQLS.BANNER,
  banners: BANNER_QUERY_GQLS.BANNERS,
  bannersWithAllBannerInfo: BANNER_QUERY_GQLS.BANNERS_WITH_ALL_BANNER_INFO,
  billingItemsList: BILLING_ITEM_QUERY_GQLS.BILLING_ITEMS_LIST,
  brand: BRAND_QUERY_GQLS.BRAND,
  brands: BRAND_QUERY_GQLS.BRANDS,
  categories: CATEGORY_QUERY_GQLS.CATEGORIES,
  categoriesForFilter: CATEGORY_QUERY_GQLS.CATEGORIES_FOR_FILTER,
  categoriesForTreeSelect: CATEGORY_QUERY_GQLS.CATEGORIES_FOR_TREE_SELECT,
  categoriesList: CATEGORY_QUERY_GQLS.CATEGORIES_LIST,
  couponGroup: COUPON_GROUP_QUERY_GQLS.COUPON_GROUP,
  couponGroups: COUPON_GROUP_QUERY_GQLS.COUPON_GROUPS,
  couponKakaoTemplates: COUPON_GROUP_QUERY_GQLS.COUPON_KAKAO_TEMPLATES,
  coupons: COUPON_GROUP_QUERY_GQLS.COUPONS,
  customerGrade: CUSTOMER_GRADE_QUERY_GQLS.CUSTOMER_GRADE,
  customerGradeTable: CUSTOMER_GRADE_QUERY_GQLS.CUSTOMER_GRADE_TABLE,
  dailyReport: DEFAULT_QUERY_GQLS.DAILY_STATISTICS,
  dashboardByAdmin: DASHBOARD_QUERY_GQLS.DASHBOARD_BY_ADMIN,
  dashboardBySeller: DASHBOARD_QUERY_GQLS.DASHBOARD_BY_SELLER,
  displayCollection: DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTION,
  displayCollections: DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTIONS,
  displayCollectionsForList: DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTIONS_FOR_LIST,
  displayCollectionsWithDisplayProductInfos:
    DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTIONS_WITH_DISPLAY_PRODUCT_INFOS,
  displayProductInfos: DISPLAY_PRODUCT_INFO_QUERY_GQLS.DISPLAY_PRODUCT_INFOS,
  excelExportRequests: EXCEL_REQUEST_QUERY_GQLS.EXCEL_EXPORT_REQUESTS,
  excelImportRequests: EXCEL_REQUEST_QUERY_GQLS.EXCEL_IMPORT_REQUESTS,
  formNotice: NOTICE_QUERY_GQLS.FORM_NOTICE,
  inactivatedUserListTable: USER_QUERY_GQLS.INACTIVATED_USER_LIST_TABLE,
  incompleteProductsByRole: PRODUCT_QUERY_GQLS.INCOMPLETE_PRODUCTS_BY_ROLE,
  informPushQueue: DEFAULT_QUERY_GQLS.INFORM_PUSH_QUEUE,
  informPushQueues: DEFAULT_QUERY_GQLS.INFORM_PUSH_QUEUES,
  initData: COMMON_QUERY_GQLS.INIT_DATA,
  leftUserListTable: USER_QUERY_GQLS.LEFT_USER_LIST_TABLE,
  mileageHistories: MILEAGE_QUERY_GQLS.MILEAGE_HISTORIES,
  monthlyReport: DEFAULT_QUERY_GQLS.MONTHLY_STATISTICS,
  notices: DEFAULT_QUERY_GQLS.NOTICES,
  order: ORDER_QUERY_GQLS.ORDER,
  orderItemCancelReasons: ORDER_QUERY_GQLS.ORDER_ITEM_CANCEL_REASONS,
  orderItemCount: ORDER_QUERY_GQLS.ORDER_ITEM_COUNT,
  orderItemReturnReasons: ORDER_QUERY_GQLS.ORDER_ITEM_RETURN_REASONS,
  orderItems: ORDER_QUERY_GQLS.ORDER_ITEMS,
  orderItemsReturn: ORDER_QUERY_GQLS.ORDER_ITEMS_RETURN,
  partnerListTable: USER_QUERY_GQLS.PARTNER_LIST_TABLE,
  partnerNoticeTable: NOTICE_QUERY_GQLS.PARTNER_NOTICE_TABLE,
  product: PRODUCT_QUERY_GQLS.PRODUCT,
  productUpdateInfo: PRODUCT_QUERY_GQLS.PRODUCT_UPDATE_INFO,
  productCount: PRODUCT_QUERY_GQLS.PRODUCT_COUNT,
  productListTable: PRODUCT_QUERY_GQLS.PRODUCT_LIST_TABLE,
  productOptions: PRODUCT_OPTION_QUERY_GQLS.PRODUCT_OPTIONS,
  productQnaList: PRODUCT_QNA_QUERY_GQLS.PRODUCT_QNA_LIST,
  productSelectModal: PRODUCT_QUERY_GQLS.PRODUCT_SELECT_MODAL,
  productsByTransferTable: PRODUCT_QUERY_GQLS.PRODUCTS_BY_TRANSFER_TABLE,
  remoteAreas: DEFAULT_QUERY_GQLS.REMOTE_AREAS,
  reportTotal: DEFAULT_QUERY_GQLS.TOTAL_SALES_STATISTIC,
  reviewGroupList: REVIEW_QUERY_GQLS.REVIEW_GROUP_LIST,
  reviewList: REVIEW_QUERY_GQLS.REVIEW_LIST,
  selectBrands: BRAND_QUERY_GQLS.SELECT_BRANDS,
  selectCustomerGrades: CUSTOMER_GRADE_QUERY_GQLS.SELECT_CUSTOMER_GRADES,
  sellerAnalysis: DEFAULT_QUERY_GQLS.SELLER_SALES_STATISTICS,
  sellerAnalysisTotal: DEFAULT_QUERY_GQLS.TOTAL_SELLER_STATISTIC,
  sellerBillingsList: BILLING_QUERY_GQLS.SELLER_BILLINGS_LIST,
  sellerByProductForm: SELLER_QUERY_GQLS.SELLER_BY_PRODUCT_FORM,
  sellerCount: SELLER_QUERY_GQLS.SELLER_COUNT,
  sellerDetail: SELLER_QUERY_GQLS.SELLER_DETAIL,
  sellerDetailInfo: SELLER_QUERY_GQLS.SELLER_DETAIL_INFO,
  sellerInfo: SELLER_QUERY_GQLS.SELLER_INFO,
  sellerInfoChange: SELLER_QUERY_GQLS.SELLER_INFO_CHANGE,
  sellerListTable: SELLER_QUERY_GQLS.SELLER_LIST_TABLE,
  sellerProductAnalysis: DEFAULT_QUERY_GQLS.SELLER_STATISTICS_BY_ROLE,
  sellerRequestListTable: SELLER_QUERY_GQLS.SELLER_REQUEST_LIST_TABLE,
  sellers: SELLER_QUERY_GQLS.SELLERS,
  sellersByMainTable: SELLER_QUERY_GQLS.SELLERS_BY_MAIN_TABLE,
  sellersByPartnerList: SELLER_QUERY_GQLS.SELLERS_BY_PARTNER_LIST,
  sellersByTransferTable: SELLER_QUERY_GQLS.SELLERS_BY_TRANSFER_TABLE,
  sellersMinimumInfo: SELLER_QUERY_GQLS.SELLERS_MINIMUM_INFO,
  serverConfigs: DEFAULT_QUERY_GQLS.SERVER_CONFIGS,
  shippingTrack: ORDER_QUERY_GQLS.SHIPPING_TRACK,
  sku: SKU_QUERY_GQLS.SKU,
  skus: SKU_QUERY_GQLS.SKUS,
  skuHistory: SKU_QUERY_GQLS.SKU_HISTORY,
  userCouponGroupModalTable: COUPON_GROUP_QUERY_GQLS.USER_COUPON_GROUP_MODAL_TABLE,
  userCouponTable: COUPON_GROUP_QUERY_GQLS.USER_COUPON_TABLE,
  userDetail: USER_QUERY_GQLS.USER_DETAIL,
  userEmail: USER_QUERY_GQLS.USER_EMAIL,
  userListTable: USER_QUERY_GQLS.USER_LIST_TABLE,
  userListForManager: USER_QUERY_GQLS.USER_LIST_FOR_MANAGER,
  users: USER_QUERY_GQLS.USERS,
  usersByTransferTable: USER_QUERY_GQLS.USERS_BY_TRANSFER_TABLE,
  weeklyReport: DEFAULT_QUERY_GQLS.WEEKLY_STATISTICS,
  minAppVersion: APP_VERSION_QUERY_GQLS.MIN_APP_VERSION,
  productPromotions: PRODUCT_PROMOTION_QUERY_GQLS.PRODUCT_PROMOTIONS,
  membershipUserList: memberships.membershipUserList,
  membershipSubscribeInfos: memberships.membershipSubscribeInfos,
}

export const EXCEL_UPLOAD_MUTATION_KEY2GQLS = {
  createBulkReviewByFitpetAdminExcel: REVIEW_MUTATION_GQLS.CREATE_BULK_REVIEW_BY_FITPET_ADMIN_EXCEL,
  createProductPromotionsByExcel: DEFAULT_MUTATION_GQLS.CREATE_PRODUCT_PROMOTIONS_BY_EXCEL,
  createProductsByExcel: DEFAULT_MUTATION_GQLS.CREATE_PRODUCTS_BY_EXCEL,
  createRemoteAreaByExcel: DEFAULT_MUTATION_GQLS.CREATE_REMOTE_AREA_BY_EXCEL,
  createSkusByExcel: SKU_MUTATION_GQLS.CREATE_SKUS_BY_EXCEL,
  createOrUpdateSkusByExcel: SKU_MUTATION_GQLS.CREATE_OR_UPDATE_SKUS_BY_EXCEL,
  updateMileageByExcel: DEFAULT_MUTATION_GQLS.UPDATE_MILEAGE_BY_EXCEL,
  updateProductSearchKeywordsByExcel: DEFAULT_MUTATION_GQLS.UPDATE_PRODUCT_SEARCH_KEYWORDS_BY_EXCEL,
  updateShippingByExcel: SHIPPING_MUTATION_GQLS.UPDATE_SHIPPING_BY_EXCEL,
  updateSkusByExcel: SKU_MUTATION_GQLS.UPDATE_SKUS_BY_EXCEL,
  issueManualCouponsByExcel: DEFAULT_MUTATION_GQLS.ISSUE_MANUAL_COUPONS_BY_EXCEL,
  updateProductMileagesByExcel: PRODUCT_MUTATION_GQLS.UPDATE_PRODUCT_MILEAGES_BY_EXCEL,
  updateBulkProductsByExcel: PRODUCT_MUTATION_GQLS.UPDATE_BULK_PRODUCTS_BY_EXCEL,
}

export const EXCEL_DOWNLOAD_MUTATION_KEY2GQLS = {
  requestAdminBillingItemExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_ADMIN_BILLING_ITEM_EXCEL_FILE,
  requestCouponExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_COUPON_EXCEL_FILE,
  requestOrderExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_ORDER_EXCEL_FILE,
  requestProductExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_PRODUCT_EXCEL_FILE,
  requestProductExcelFileByAdmin: EXCEL_REQUEST_MUTATION_GQLS.REQUEST_PRODUCT_EXCEL_FILE_BY_ADMIN,
  requestAuditExcel: EXCEL_REQUEST_MUTATION_GQLS.REQUEST_AUDIT_EXCEL,
  requestReadyForShippingOrderFitpetWmsExcelFile:
    EXCEL_REQUEST_MUTATION_GQLS.REQUEST_READY_FOR_SHIPPING_ORDER_FITPET_WMS_EXCEL_FILE,
  requestReadyForShippingOrderExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_READY_FOR_SHIPPING_ORDER_EXCEL_FILE,
  requestSellerBillingItemExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_SELLER_BILLING_ITEM_EXCEL_FILE,
  requestSkuExcelFile: SKU_MUTATION_GQLS.REQUEST_SKU_EXCEL_FILE,
  requestUserExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_USER_EXCEL_FILE,
  requestReadyForShippingOrderWmsExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_READY_FOR_SHIPPING_ORDER_WMS_EXCEL_FILE,
  requestDisplayProductPromotionExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_DISPLAY_PRODUCT_PROMOTION_EXCEL_FILE,
  requestMembershipUserExcelFile: EXCEL_REQUEST_MUTATION_GQLS.REQUEST_MEMBERSHIP_USER_EXCEL_FILE,
}

// <GQL(.*)MUTATION.(.*).REQUEST
// <MMutation> component를 사용할 경우 필수 등록
// _app.tsx의 TOKEN_SIGNIN은 hook을 사용할 수 없어 직접 client.mutate 사용
export const MUTATION_KEY2GQLS = {
  ...EXCEL_UPLOAD_MUTATION_KEY2GQLS,
  ...EXCEL_DOWNLOAD_MUTATION_KEY2GQLS,
  acceptExchangeReturnInfos: ORDER_MUTATION_GQLS.ACCEPT_EXCHANGE_RETURN_INFOS,
  acceptReturnInfos: ORDER_MUTATION_GQLS.ACCEPT_RETURN_INFOS,
  activateCustomerGrade: CUSTOMER_GRADE_MUTATION_GQLS.ACTIVATE_CUSTOMER_GRADE,
  addAdminPermission: USER_MUTATION_GQLS.ADD_ADMIN_PERMISSION,
  answerProductQuestion: PRODUCT_QNA_MUTATION_GQLS.ANSWER_PRODUCT_QUESTION,
  approveRequest: STATUS_MUTATION_GQLS.APPROVE_REQUEST,
  approveRequests: STATUS_MUTATION_GQLS.APPROVE_REQUESTS,
  approveUpdateRequest: STATUS_MUTATION_GQLS.APPROVE_UPDATE_REQUEST,
  associateSearchToken: DEFAULT_MUTATION_GQLS.ASSOCIATE_SEARCH_TOKEN,
  cancelOrderItems: ORDER_MUTATION_GQLS.CANCEL_ORDER_ITEMS,
  changeChiefSellerManager: SELLER_MUTATION_GQLS.CHANGE_CHIEF_SELLER_MANAGER,
  checkOrderItems: ORDER_MUTATION_GQLS.CHECK_ORDER_ITEMS,
  clearBannerCache: CACHE_MUTATION_GQLS.CLEAR_BANNER_CACHE,
  clearBestCache: CACHE_MUTATION_GQLS.CLEAR_BEST_CACHE,
  clearHomeCache: CACHE_MUTATION_GQLS.CLEAR_HOME_CACHE,
  confirmCancelOrderItem: ORDER_MUTATION_GQLS.CONFIRM_CANCEL_ORDER_ITEM,
  confirmExchangeReturnInfos: ORDER_MUTATION_GQLS.CONFIRM_EXCHANGE_RETURN_INFOS,
  confirmReturnInfos: ORDER_MUTATION_GQLS.CONFIRM_RETURN_INFOS,
  confirmSellerBilling: BILLING_MUTATION_GQLS.CONFIRM_SELLER_BILLING,
  confirmUserCertNumber: USER_MUTATION_GQLS.CONFIRM_USER_CERT_NUMBER,
  copyProduct: PRODUCT_MUTATION_GQLS.COPY_PRODUCT,
  createAd: AD_MUTATION_GQLS.CREATE_AD,
  createBrandByFitpetAdmin: BRAND_MUTATION_GQLS.CREATE_BRAND_BY_FITPET_ADMIN,
  createBrandBySeller: BRAND_MUTATION_GQLS.CREATE_BRAND_BY_SELLER,
  createCategory: CATEGORY_MUTATION_GQLS.CREATE_CATEGORY,
  createCouponGroup: COUPON_GROUP_MUTATION_GQLS.CREATE_COUPON_GROUP,
  createCustomerGrade: CUSTOMER_GRADE_MUTATION_GQLS.CREATE_CUSTOMER_GRADE,
  createDisplayCollection: DISPLAY_COLLECTION_MUTATION_GQLS.CREATE_DISPLAY_COLLECTION,
  createDisplayProductInfos: DISPLAY_PRODUCT_INFO_MUTATION_GQLS.CREATE_DISPLAY_PRODUCT_INFOS,
  createDisplayProductInfosWithProductPromotions:
    DISPLAY_PRODUCT_INFO_MUTATION_GQLS.CREATE_DISPLAY_PRODUCT_INFOS_WITH_PRODUCT_PROMOTIONS,
  createHomeBottomBanner: BANNER_MUTATION_GQLS.CREATE_HOME_BOTTOM_BANNER,
  createHomeMiddleBanner: BANNER_MUTATION_GQLS.CREATE_HOME_MIDDLE_BANNER,
  createHomeMultiLineBanner: BANNER_MUTATION_GQLS.CREATE_HOME_MULTI_LINE_BANNER,
  createNotice: NOTICE_MUTATION_GQLS.CREATE_NOTICE,
  createProductByFitpetAdmin: PRODUCT_MUTATION_GQLS.CREATE_PRODUCT_BY_FITPET_ADMIN,
  createProductBySeller: PRODUCT_MUTATION_GQLS.CREATE_PRODUCT_BY_SELLER,
  createProductPromotion: PRODUCT_PROMOTION_MUTATION_GQLS.CREATE_PRODUCT_PROMOTION,
  createProductTopBanner: BANNER_MUTATION_GQLS.CREATE_PRODUCT_TOP_BANNER,
  createSellerByFitpetAdmin: SELLER_MUTATION_GQLS.CREATE_SELLER_BY_FITPET_ADMIN,
  createSellerManager: SELLER_MUTATION_GQLS.CREATE_SELLER_MANAGER,
  createShippingPreset: SHIPPING_PRESET_MUTATION_GQLS.CREATE_SHIPPING_PRESET,
  createSlimBanner: BANNER_MUTATION_GQLS.CREATE_SLIM_BANNER,
  createTopRollBanner: BANNER_MUTATION_GQLS.CREATE_TOP_ROLL_BANNER,
  createUser: USER_MUTATION_GQLS.CREATE_USER,
  completeShippingOrderItems: ORDER_MUTATION_GQLS.COMPLETE_SHIPPING_ORDER_ITEMS,
  deleteAds: AD_MUTATION_GQLS.DELETE_ADS,
  deleteBanners: BANNER_MUTATION_GQLS.DELETE_BANNERS,
  deleteCoupon: COUPON_MUTATION_GQLS.DELETE_COUPON,
  deleteCouponGroup: COUPON_GROUP_MUTATION_GQLS.DELETE_COUPON_GROUP,
  deleteCouponGroupWithUnusedCoupons: COUPON_GROUP_MUTATION_GQLS.DELETE_COUPON_GROUP_WITH_UNUSED_COUPONS,
  deleteCoupons: DEFAULT_MUTATION_GQLS.DELETE_COUPONS,
  deleteCouponsByGroup: DEFAULT_MUTATION_GQLS.DELETE_COUPONS_BY_GROUP,
  deleteCustomerGrade: CUSTOMER_GRADE_MUTATION_GQLS.DELETE_CUSTOMER_GRADE,
  deleteDisplayCollection: DISPLAY_COLLECTION_MUTATION_GQLS.DELETE_DISPLAY_COLLECTION,
  deleteDisplayCollections: DISPLAY_COLLECTION_MUTATION_GQLS.DELETE_DISPLAY_COLLECTIONS,
  deleteDisplayProductInfo: DISPLAY_PRODUCT_INFO_MUTATION_GQLS.DELETE_DISPLAY_PRODUCT_INFO,
  deleteDisplayProductInfos: DISPLAY_PRODUCT_INFO_MUTATION_GQLS.DELETE_DISPLAY_PRODUCT_INFOS,
  deleteInactiveUsers: DEFAULT_MUTATION_GQLS.DELETE_INACTIVE_USERS,
  deleteIncompleteProducts: PRODUCT_MUTATION_GQLS.DELETE_INCOMPLETE_PRODUCTS,
  deleteNotices: DEFAULT_MUTATION_GQLS.DELETE_NOTICES,
  deleteProductQuestions: PRODUCT_QNA_MUTATION_GQLS.DELETE_PRODUCT_QUESTIONS,
  deletePush: PUSH_MUTATION_GQLS.DELETE_PUSH,
  deleteRequests: STATUS_MUTATION_GQLS.DELETE_REQUESTS,
  deleteReviews: REVIEW_MUTATION_GQLS.DELETE_REVIEWS,
  deleteSellerManager: SELLER_MUTATION_GQLS.DELETE_SELLER_MANAGER,
  deleteShippingPreset: SHIPPING_PRESET_MUTATION_GQLS.DELETE_SHIPPING_PRESET,
  emailSignin: USER_MUTATION_GQLS.EMAIL_SIGNIN,
  emailSignup: USER_MUTATION_GQLS.EMAIL_SIGNUP,
  findId: USER_MUTATION_GQLS.FIND_ID,
  finishAd: AD_MUTATION_GQLS.FINISH_AD,
  issueManualCoupons: COUPON_GROUP_MUTATION_GQLS.ISSUE_MANUAL_COUPONS,
  leaveUsersByFitpetAdmin: DEFAULT_MUTATION_GQLS.LEAVE_USERS_BY_FITPET_ADMIN,
  rejectExchangeReturnInfos: ORDER_MUTATION_GQLS.REJECT_EXCHANGE_RETURN_INFOS,
  rejectRequest: STATUS_MUTATION_GQLS.REJECT_REQUEST,
  rejectReturnInfos: ORDER_MUTATION_GQLS.REJECT_RETURN_INFOS,
  rejectUpdateRequest: STATUS_MUTATION_GQLS.REJECT_UPDATE_REQUEST,
  removeAdminPermission: USER_MUTATION_GQLS.REMOVE_ADMIN_PERMISSION,
  requestAdminBillingItemExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_ADMIN_BILLING_ITEM_EXCEL_FILE,
  requestSellerBillingItemExcelFile: DEFAULT_MUTATION_GQLS.REQUEST_SELLER_BILLING_ITEM_EXCEL_FILE,
  resetPassword: USER_MUTATION_GQLS.RESET_PASSWORD,
  returnOrderItems: ORDER_MUTATION_GQLS.RETURN_ORDER_ITEMS,
  returnedReturnInfos: ORDER_MUTATION_GQLS.RETURNED_RETURN_INFOS,
  sendEmail: INFORM_MUTATION_GQLS.SEND_EMAIL,
  sendPush: PUSH_MUTATION_GQLS.SEND_PUSH,
  sendSms: INFORM_MUTATION_GQLS.SEND_SMS,
  sendUserCertNumber: USER_MUTATION_GQLS.SEND_USER_CERT_NUMBER,
  startShippings: SHIPPING_MUTATION_GQLS.START_SHIPPINGS,
  suspendRequest: STATUS_MUTATION_GQLS.SUSPEND_REQUEST,
  suspendRequests: STATUS_MUTATION_GQLS.SUSPEND_REQUESTS,
  toggleCategoryVisible: CATEGORY_MUTATION_GQLS.TOGGLE_CATEGORY_VISIBLE,
  tokenSignin: USER_MUTATION_GQLS.TOKEN_SIGNIN,
  updateAd: AD_MUTATION_GQLS.UPDATE_AD,
  updateBrandByFitpetAdmin: BRAND_MUTATION_GQLS.UPDATE_BRAND_BY_FITPET_ADMIN,
  updateBrandBySeller: BRAND_MUTATION_GQLS.UPDATE_BRAND_BY_SELLER,
  updateCategory: CATEGORY_MUTATION_GQLS.UPDATE_CATEGORY,
  updateCategoryImage: CATEGORY_MUTATION_GQLS.UPDATE_CATEGORY_IMAGE,
  updateCategorySlug: CATEGORY_MUTATION_GQLS.UPDATE_CATEGORY_SLUG,
  updateCouponGroup: COUPON_GROUP_MUTATION_GQLS.UPDATE_COUPON_GROUP,
  updateCouponGroupStatus: COUPON_GROUP_MUTATION_GQLS.UPDATE_COUPON_GROUP_STATUS,
  updateCustomerGrade: CUSTOMER_GRADE_MUTATION_GQLS.UPDATE_CUSTOMER_GRADE,
  updateDisplayCollection: DISPLAY_COLLECTION_MUTATION_GQLS.UPDATE_DISPLAY_COLLECTION,
  updateDateOfDisplayCollection: DISPLAY_COLLECTION_MUTATION_GQLS.UPDATE_DATE_OF_DISPLAY_COLLECTION,
  updateDisplayOrder: COMMON_MUTATION_GQLS.UPDATE_DISPLAY_ORDER,
  updateDisplayOrderForBanner: BANNER_MUTATION_GQLS.UPDATE_DISPLAY_ORDER_FOR_BANNER,
  updateHomeBottomBanner: BANNER_MUTATION_GQLS.UPDATE_HOME_BOTTOM_BANNER,
  updateHomeMiddleBanner: BANNER_MUTATION_GQLS.UPDATE_HOME_MIDDLE_BANNER,
  updateHomeMultiLineBanner: BANNER_MUTATION_GQLS.UPDATE_HOME_MULTI_LINE_BANNER,
  updateMileage: MILEAGE_MUTATION_GQLS.UPDATE_MILEAGE,
  updateMileageConfig: DEFAULT_MUTATION_GQLS.UPDATE_MILEAGE_CONFIG,
  updateMobileNumber: USER_MUTATION_GQLS.UPDATE_MOBILE_NUMBER,
  updateNotice: NOTICE_MUTATION_GQLS.UPDATE_NOTICE,
  updateOrderCustomerAddress: SHIPPING_MUTATION_GQLS.UPDATE_ORDER_CUSTOMER_ADDRESS,
  updateProductByFitpetAdmin: PRODUCT_MUTATION_GQLS.UPDATE_PRODUCT_BY_FITPET_ADMIN,
  updateProductBySeller: PRODUCT_MUTATION_GQLS.UPDATE_PRODUCT_BY_SELLER,
  updateProductPromotion: PRODUCT_PROMOTION_MUTATION_GQLS.UPDATE_PRODUCT_PROMOTION,
  updateProductPromotions: PRODUCT_PROMOTION_MUTATION_GQLS.UPDATE_PRODUCT_PROMOTIONS,
  updateProductTopBanner: BANNER_MUTATION_GQLS.UPDATE_PRODUCT_TOP_BANNER,
  updateProductsSoldOut: PRODUCT_MUTATION_GQLS.UPDATE_PRODUCTS_SOLD_OUT,
  updateProductsSpecialPriceByExcel: null,
  updatePush: PUSH_MUTATION_GQLS.UPDATE_PUSH,
  updateRefundAccount: USER_MUTATION_GQLS.UPDATE_REFUND_ACCOUNT,
  updateReview: REVIEW_MUTATION_GQLS.UPDATE_REVIEW,
  updateReviewByFitpetAdmin: REVIEW_MUTATION_GQLS.UPDATE_REVIEW_BY_FITPET_ADMIN,
  updateReviewGroupProducts: REVIEW_MUTATION_GQLS.UPDATE_REVIEW_GROUP_PRODUCTS,
  updateSellerByFitpetAdmin: SELLER_MUTATION_GQLS.UPDATE_SELLER_BY_FITPET_ADMIN,
  updateSellerByUserRequest: SELLER_MUTATION_GQLS.UPDATE_SELLER_BY_USER_REQUEST,
  updateSellerNotification: SELLER_MUTATION_GQLS.UPDATE_SELLER_NOTIFICATION,
  updateServerConfig: DEFAULT_MUTATION_GQLS.UPDATE_SERVER_CONFIG,
  updateShippingPreset: SHIPPING_PRESET_MUTATION_GQLS.UPDATE_SHIPPING_PRESET,
  updateShippings: SHIPPING_MUTATION_GQLS.UPDATE_SHIPPINGS,
  updateSlimBanner: BANNER_MUTATION_GQLS.UPDATE_SLIM_BANNER,
  updateSku: SKU_MUTATION_GQLS.UPDATE_SKU,
  updateTopRollBanner: BANNER_MUTATION_GQLS.UPDATE_TOP_ROLL_BANNER,
  updateUserByCustomer: USER_MUTATION_GQLS.UPDATE_USER_BY_CUSTOMER,
  updateUserByFitpetAdmin: USER_MUTATION_GQLS.UPDATE_USER_BY_FITPET_ADMIN,
  updateUserPasswordByFitpetAdmin: USER_MUTATION_GQLS.UPDATE_USER_PASSWORD_BY_FITPET_ADMIN,
  uploadSearchToken: DEFAULT_MUTATION_GQLS.UPLOAD_SEARCH_TOKEN,
  updateMinAppVersion: APP_VERSION_MUTATION_GQLS.UPDATE_MIN_APP_VERSION,
  checkUnconfirmedTransaction: ORDER_MUTATION_GQLS.CHECK_UNCONFIRMED_TRANSACTION,
  deleteProductPromotion: PRODUCT_PROMOTION_MUTATION_GQLS.DELETE_PRODUCT_PROMOTION,
  updateProductsSelling: PRODUCT_MUTATION_GQLS.UPDATE_PRODUCTS_SELLING,
  approveProductsSelling: PRODUCT_MUTATION_GQLS.APPROVE_PRODUCTS_SELLING,
  cancelIssuingManualCoupons: EXCEL_REQUEST_MUTATION_GQLS.CANCEL_ISSUING_MANUAL_COUPONS,
}

export const makeGqls = (
  queryOrMutation: string[],
  requests: { [index: string]: any },
  variables: { [index: string]: object }
) => {
  return queryOrMutation.map((key: string) => {
    return {
      [key]: {
        REQUEST: requests[key],
        VARIABLES: variables[key],
      },
    }
  })
}
