export const ROUTE_KEYS = {
  ACCOUNTS: 'accounts',
  ADD: 'add',
  ALARM_LIST: 'alarm-list',
  ANALYSIS_PRODUCT: 'analysis-product',
  ANALYSIS_PRODUCT_BY_SELLER: 'analysis-product-by-seller',
  ANALYSIS_SELLER: 'analysis-seller',
  BILLINGS: 'billings',
  BILLING_LIST: 'billing-list',
  BILLING_LIST_BY_ORDER: 'billing-list-by-order',
  BILLING_LIST_BY_TAX_INVOICE: 'billing-list-by-tax-invoice',
  BILLING_LIST_BY_VAT_DECLARATION: 'billing-list-by-vat-declaration',
  BOARDS: 'boards',
  BOARD_LIST_PRODUCT_QNA: 'product-qna-list',
  BOARD_LIST_REVIEW: 'review-list',
  BOARD_LIST_REVIEW_GROUP: 'review-group-list',
  BRAND_INFO: 'brand-info',
  BRAND_INFORMATION: 'brand-information',
  BRAND_LIST: 'brand-list',
  CATEGORY: 'category',
  CONFIGURATIONS: 'configurations',
  CUSTOMERS: 'customers',
  CUSTOMER_GRADE: 'customer-grade',
  CUSTOMER_GRADE_CONFIG: 'customer-grade-config',
  DAILY_REPORT: 'daily-report',
  DASHBOARD: 'dashboard',
  EMAIL: 'email',
  ETC: 'etc',
  EXCEL_REQUEST_LIST: 'excel-request-list',
  EXCEL_REQUEST_ORDER: 'excel-request-order',
  FIND_ID: 'find-id',
  FIND_PASSWORD: 'find-password',
  INACTIVATED_USER_LIST: 'inactivated-user-list',
  LAYOUTS: 'layouts',
  LAYOUT_BANNER_LIST: 'banner-list',
  LAYOUT_BRAND_LIST: 'brand-list',
  LAYOUT_CATEGORY_EXPOSE_LIST: 'category-expose-list',
  LAYOUT_DISPLAY_COLLECTION_LIST: 'display-collection-list',
  LAYOUT_TODAY_SPECIAL_PRICE_LIST: 'today-special-price-list',
  LAYOUT_EVENT_LIST: 'event-list',
  LEFT_USER_LIST: 'left-user-list',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MANAGE_SEARCH_TOKEN: 'manage-search-token',
  MESSAGE: 'message',
  MONTHLY_REPORT: 'monthly-report',
  MY_ACCOUNT: 'my-account',
  MY_SELLER_LIST: 'my-seller-list',
  NOTICE: 'notice',
  NOTICES: 'notices',
  NOTICE_LIST_SELLER: 'seller',
  NOTIFY_SUSPEND_ACCOUNT: 'notify-suspend-account',
  ORDERS: 'orders',
  ORDER_LIST_ALL: 'all',
  ORDER_LIST_CANCELED: 'cancel',
  ORDER_LIST_CONFIRMED: 'confirm',
  ORDER_LIST_EXCHANGE: 'exchange',
  ORDER_LIST_NEW_ORDER: 'new',
  ORDER_LIST_RETURN: 'return',
  ORDER_LIST_SHIPPING: 'shipping',
  ORDER_LIST_UNCONFIRMED_PAYMENT: 'unpaid',
  PARTNERS: 'partners',
  PARTNER_LIST: 'partner-list',
  POLICIES: 'policies',
  PRIVACY: 'privacy',
  PRODUCTS: 'products',
  PRODUCT_ADD: 'products/add',
  PRODUCT_EXCEL_UPLOAD: 'excel-upload',
  PRODUCT_LIST: 'product-list',
  PRODUCT_LIST_DELETE: 'delete-product-list',
  PRODUCT_LIST_MY: 'my-product-list',
  PRODUCT_LIST_REQUEST: 'request-product-list',
  PROFIT_LIST_BY_PRODUCT: 'profit-list-by-product',
  PROMOTIONS: 'promotions',
  PROMOTION_COUPON_ADD: 'coupon-add',
  PROMOTION_COUPON_DETAIL: 'coupon-detail',
  PROMOTION_COUPON_LIST: 'coupon-list',
  PROMOTION_COUPON_LIST_ISSUED: 'issued-coupon-list',
  EXCEL_IMPORT_REQUESTS_COUPON: 'excel-import-requests-coupon',
  PROMOTION_MILEAGE_CONFIG: 'mileage-config',
  PROMOTION_MILEAGE_HISTORY_LIST: 'mileage-history-list',
  PUSH_LIST: 'push-list',
  PUSH_SEND: 'push-send',
  REGISTER_BRAND_MANAGER: 'register-brand-manager',
  REMOTE_AREA_LIST: 'remote-area-list',
  SELLER_CATEGORY: 'seller-category',
  SELLER_BILLING_LIST: 'seller-billing-list',
  SELLER_INFORMATION: 'seller-information',
  SELLER_INFORMATION_CHANGE: 'seller-information-change',
  SELLER_LIST: 'seller-list',
  SELLER_NOTICE: 'seller-notice',
  SELLER_REQUEST_LIST: 'seller-request-list',
  SELLER_SHIPPING_PRESET: 'seller-shipping-preset',
  SELLER_SHOP_INFORMATION: 'seller-shop-information',
  SIGNUP: 'signup',
  SKU_STOCK_LIST: 'sku-stock-list',
  APP_VERSION: 'app-version',
  HOME_BRANDS: 'home-brands',
  SMS: 'sms',
  STATISTICS: 'statistics',
  TERMS: 'terms',
  USER_LIST: 'user-list',
  WEEKLY_REPORT: 'weekly-report',
  MEMBERSHIP: 'membership',
  MEMBERSHIP_USER_LIST: 'membership-list',
}

const ROUTE_ACCOUNTS_BASE = `/${ROUTE_KEYS.ACCOUNTS}`
const ROUTE_BILLINGS_BASE = `/${ROUTE_KEYS.BILLINGS}`
const ROUTE_BOARDS_BASE = `/${ROUTE_KEYS.BOARDS}`
const ROUTE_CONFIGURATIONS_BASE = `/${ROUTE_KEYS.CONFIGURATIONS}`
const ROUTE_ETC_BASE = `/${ROUTE_KEYS.ETC}`
const ROUTE_LAYOUTS_BASE = `/${ROUTE_KEYS.LAYOUTS}`
const ROUTE_NOTICES_BASE = `/${ROUTE_KEYS.NOTICES}`
const ROUTE_ORDERS_BASE = `/${ROUTE_KEYS.ORDERS}`
const ROUTE_PARTNER_BASE = `/${ROUTE_KEYS.PARTNERS}`
const ROUTE_POLICIES_BASE = `/${ROUTE_KEYS.POLICIES}`
const ROUTE_PRODUCTS_BASE = `/${ROUTE_KEYS.PRODUCTS}`
const ROUTE_PROMOTIONS_BASE = `/${ROUTE_KEYS.PROMOTIONS}`
const ROUTE_STATISTICS_BASE = `/${ROUTE_KEYS.STATISTICS}`
const ROUTE_MEMBERSHIP_BASE = `/${ROUTE_KEYS.MEMBERSHIP}`

const ROUTES = {
  DASHBOARD: `/${ROUTE_KEYS.DASHBOARD}`,
  FIND_ID: `/${ROUTE_KEYS.FIND_ID}`,
  HOME: '/',
  LOGIN: `/${ROUTE_KEYS.LOGIN}`,
  NOTIFY_SUSPEND_ACCOUNT: `/${ROUTE_KEYS.NOTIFY_SUSPEND_ACCOUNT}`,
  REGISTER_BRAND_MANAGER: `/${ROUTE_KEYS.REGISTER_BRAND_MANAGER}`,
  FIND_PASSWORD: `/${ROUTE_KEYS.FIND_PASSWORD}`,
  SIGNUP: `/${ROUTE_KEYS.SIGNUP}`,

  ACCOUNTS: {
    BASE: `${ROUTE_ACCOUNTS_BASE}`,
    CUSTOMER_GRADE: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.CUSTOMER_GRADE}`,
    CUSTOMER_GRADE_ADD: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.CUSTOMER_GRADE}/add`,
    CUSTOMER_GRADE_CONFIG: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.CUSTOMER_GRADE_CONFIG}`,
    CUSTOMER_GRADE_EDIT: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.CUSTOMER_GRADE}/[id]/edit`,
    EMAIL: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.EMAIL}`,
    INACTIVATED_USER_LIST: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.INACTIVATED_USER_LIST}`,
    LEFT_USER_LIST: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.LEFT_USER_LIST}`,
    MESSAGE: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.MESSAGE}`,
    MY_ACCOUNT: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.MY_ACCOUNT}`,
    PUSH_DETAIL: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.PUSH_LIST}/[id]`,
    PUSH_LIST: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.PUSH_LIST}`,
    PUSH_SEND: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.PUSH_LIST}/send`,
    SMS: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.SMS}`,
    USER_ADD: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.USER_LIST}/add`,
    USER_DETAIL: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.USER_LIST}/[id]`,
    USER_LIST: `${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.USER_LIST}`,
  },
  PARTNERS: {
    BASE: `${ROUTE_PARTNER_BASE}`,
    BRAND_ADD: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.BRAND_LIST}/add`,
    BRAND_DETAIL: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.BRAND_LIST}/[id]`,
    BRAND_LIST: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.BRAND_LIST}`,
    MY_SELLER_LIST: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.MY_SELLER_LIST}`,
    MY_SELLER_LIST_DETAIL: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.MY_SELLER_LIST}/[id]`,
    PARTNER_DETAIL: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.PARTNER_LIST}/[id]`,
    PARTNER_LIST: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.PARTNER_LIST}`,
    SELLER_ADD: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_LIST}/add`,
    SELLER_DETAIL: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_LIST}/[id]`,
    SELLER_DETAIL_CHANGE: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_LIST}/[id]/change`,
    SELLER_LIST: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_LIST}`,
    SELLER_REQUEST_DETAIL: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_REQUEST_LIST}/[id]`,
    SELLER_REQUEST_LIST: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_REQUEST_LIST}`,
    SELLER_SHIPPING_PRESET: `${ROUTE_PARTNER_BASE}/${ROUTE_KEYS.SELLER_SHIPPING_PRESET}`,
  },
  BILLINGS: {
    BASE: `${ROUTE_BILLINGS_BASE}`,
    BILLING_LIST: `${ROUTE_BILLINGS_BASE}/${ROUTE_KEYS.BILLING_LIST}`,
    BILLING_LIST_BY_ORDER: `${ROUTE_BILLINGS_BASE}/${ROUTE_KEYS.BILLING_LIST_BY_ORDER}`,
    BILLING_LIST_BY_TAX_INVOICE: `${ROUTE_BILLINGS_BASE}/${ROUTE_KEYS.BILLING_LIST_BY_TAX_INVOICE}`,
    BILLING_LIST_BY_VAT_DECLARATION: `${ROUTE_BILLINGS_BASE}/${ROUTE_KEYS.BILLING_LIST_BY_VAT_DECLARATION}`,
    PROFIT_LIST_BY_PRODUCT: `${ROUTE_BILLINGS_BASE}/${ROUTE_KEYS.PROFIT_LIST_BY_PRODUCT}`,
    SELLER_BILLING_LIST: `${ROUTE_BILLINGS_BASE}/${ROUTE_KEYS.SELLER_BILLING_LIST}`,
  },
  BOARDS: {
    BASE: `${ROUTE_BOARDS_BASE}`,
    BOARD_LIST_PRODUCT_QNA: `${ROUTE_BOARDS_BASE}/${ROUTE_KEYS.BOARD_LIST_PRODUCT_QNA}`,
    BOARD_LIST_REVIEW_GROUP: `${ROUTE_BOARDS_BASE}/${ROUTE_KEYS.BOARD_LIST_REVIEW_GROUP}`,
    BOARD_LIST_REVIEW: `${ROUTE_BOARDS_BASE}/${ROUTE_KEYS.BOARD_LIST_REVIEW}`,
  },
  CONFIGURATIONS: {
    BASE: `${ROUTE_CONFIGURATIONS_BASE}`,
    BRAND_INFORMATION: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.BRAND_INFORMATION}`,
    BRAND_INFORMATION_ADD: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.BRAND_INFORMATION}/add`,
    BRAND_INFORMATION_EDIT: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.BRAND_INFORMATION}/[id]/edit`,
    SELLER_INFORMATION: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.SELLER_INFORMATION}`,
    SELLER_INFORMATION_CHANGE: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.SELLER_INFORMATION_CHANGE}`,
    SELLER_NOTICE: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.SELLER_NOTICE}`,
    SELLER_SHOP_INFORMATION: `${ROUTE_CONFIGURATIONS_BASE}/${ROUTE_KEYS.SELLER_SHOP_INFORMATION}`,
  },
  LAYOUTS: {
    BASE: `${ROUTE_LAYOUTS_BASE}`,
    EVENT_ADD: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_EVENT_LIST}/add`,
    EVENT_DETAIL: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_EVENT_LIST}/[id]`,
    EVENT_LIST: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_EVENT_LIST}`,
    LAYOUT_BRAND_LIST: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_BRAND_LIST}`,
    LAYOUT_BANNER_DETAIL: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_BANNER_LIST}/[bannerType]`,
    LAYOUT_BANNER_LIST: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_BANNER_LIST}`,
    LAYOUT_CATEGORY_EXPOSE_LIST: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_CATEGORY_EXPOSE_LIST}`,
    LAYOUT_DISPLAY_COLLECTION_ADD: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_DISPLAY_COLLECTION_LIST}/add`,
    LAYOUT_DISPLAY_COLLECTION_DETAIL: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_DISPLAY_COLLECTION_LIST}/[id]`,
    LAYOUT_DISPLAY_COLLECTION_LIST: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_DISPLAY_COLLECTION_LIST}`,
    LAYOUT_TODAY_SPECIAL_PRICE_LIST: `${ROUTE_LAYOUTS_BASE}/${ROUTE_KEYS.LAYOUT_TODAY_SPECIAL_PRICE_LIST}`,
    HOME_BRANDS: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.HOME_BRANDS}`,
  },
  NOTICES: {
    BASE: `${ROUTE_NOTICES_BASE}`,
    NOTICE_LIST_SELLER: `${ROUTE_NOTICES_BASE}/${ROUTE_KEYS.NOTICE_LIST_SELLER}`,
  },
  ORDERS: {
    BASE: `${ROUTE_ORDERS_BASE}`,
    ORDER_DETAIL: `${ROUTE_ORDERS_BASE}/[id]`,
    ORDER_LIST_ALL: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_ALL}`,
    ORDER_LIST_CANCELED: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_CANCELED}`,
    ORDER_LIST_CONFIRMED: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_CONFIRMED}`,
    ORDER_LIST_NEW_ORDER: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_NEW_ORDER}`,
    ORDER_LIST_RETURN: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_RETURN}`,
    ORDER_LIST_EXCHANGE: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_EXCHANGE}`,
    ORDER_LIST_SHIPPING: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_SHIPPING}`,
    ORDER_LIST_UNCONFIRMED_PAYMENT: `${ROUTE_ORDERS_BASE}/${ROUTE_KEYS.ORDER_LIST_UNCONFIRMED_PAYMENT}`,
  },
  POLICIES: {
    PRIVACY: `${ROUTE_POLICIES_BASE}/${ROUTE_KEYS.PRIVACY}`,
    TERMS: `${ROUTE_POLICIES_BASE}/${ROUTE_KEYS.TERMS}`,
  },
  PRODUCTS: {
    BASE: `${ROUTE_PRODUCTS_BASE}`,
    CATEGORY: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.CATEGORY}`,
    SELLER_CATEGORY: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.SELLER_CATEGORY}`,
    PRODUCT_ADD: `${ROUTE_PRODUCTS_BASE}/add`,
    PRODUCT_DETAIL: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.PRODUCT_LIST}/[id]`,
    PRODUCT_EDIT: `${ROUTE_PRODUCTS_BASE}/[id]/edit`,
    PRODUCT_EXCEL_UPLOAD: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.PRODUCT_EXCEL_UPLOAD}`,
    PRODUCT_INFO_CHANGE: `${ROUTE_PRODUCTS_BASE}/[id]/info-change`,
    PRODUCT_LIST: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.PRODUCT_LIST}`,
    PRODUCT_LIST_DELETE: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.PRODUCT_LIST_DELETE}`,
    PRODUCT_LIST_MY: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.PRODUCT_LIST_MY}`,
    PRODUCT_LIST_REQUEST: `${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.PRODUCT_LIST_REQUEST}`,
  },
  PROMOTIONS: {
    BASE: `${ROUTE_PROMOTIONS_BASE}`,
    COUPON_ADD: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_COUPON_LIST}/add`,
    COUPON_DETAIL: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_COUPON_LIST}/[id]`,
    COUPON_ISSUE: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_COUPON_LIST}/[id]/issue`,
    COUPON_LIST: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_COUPON_LIST}`,
    COUPON_LIST_ISSUED: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_COUPON_LIST_ISSUED}`,
    EXCEL_IMPORT_REQUESTS_COUPON: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.EXCEL_IMPORT_REQUESTS_COUPON}`,
    MILEAGE_CONFIG: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_MILEAGE_CONFIG}`,
    MILEAGE_HISTORY_LIST: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PROMOTION_MILEAGE_HISTORY_LIST}`,
    PUSH_LIST: `${ROUTE_PROMOTIONS_BASE}/${ROUTE_KEYS.PUSH_LIST}`,
  },
  MEMBERSHIP: {
    BASE: `${ROUTE_MEMBERSHIP_BASE}`,
    MEMBERSHIP_USER_LIST: `${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.MEMBERSHIP_USER_LIST}`,
  },
  STATISTICS: {
    BASE: `${ROUTE_STATISTICS_BASE}`,
    ANALYSIS_PRODUCT: `${ROUTE_STATISTICS_BASE}/${ROUTE_KEYS.ANALYSIS_PRODUCT}`,
    ANALYSIS_PRODUCT_BY_SELLER: `${ROUTE_STATISTICS_BASE}/${ROUTE_KEYS.ANALYSIS_PRODUCT_BY_SELLER}`,
    ANALYSIS_SELLER: `${ROUTE_STATISTICS_BASE}/${ROUTE_KEYS.ANALYSIS_SELLER}`,
    DAILY_REPORT: `${ROUTE_STATISTICS_BASE}/${ROUTE_KEYS.DAILY_REPORT}`,
    MONTHLY_REPORT: `${ROUTE_STATISTICS_BASE}/${ROUTE_KEYS.MONTHLY_REPORT}`,
    WEEKLY_REPORT: `${ROUTE_STATISTICS_BASE}/${ROUTE_KEYS.WEEKLY_REPORT}`,
  },
  ETC: {
    BASE: `${ROUTE_ETC_BASE}`,
    EXCEL_REQUEST_LIST: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.EXCEL_REQUEST_LIST}`,
    EXCEL_REQUEST_ORDER: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.EXCEL_REQUEST_ORDER}`,
    MANAGE_SEARCH_TOKEN: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.MANAGE_SEARCH_TOKEN}`,
    REMOTE_AREA_LIST: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.REMOTE_AREA_LIST}`,
    SKU_STOCK_DETAIL: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.SKU_STOCK_LIST}/[id]`,
    SKU_STOCK_LIST: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.SKU_STOCK_LIST}`,
    APP_VERSION: `${ROUTE_ETC_BASE}/${ROUTE_KEYS.APP_VERSION}`,
  },
}

export default ROUTES
